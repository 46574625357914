import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useQuery } from "react-query";
import {
  Field,
  reduxForm,
  SubmissionError,
} from "redux-form";
import {
  Button,
  Input,
  ModalDelete,
  Textarea,
} from "expano-components";
import {
  Main,
  Dashboard,
  PageLoader,
  Select,
  DatePicker,
} from "components";
import {
  getAbsence,
  deleteAbsence,
  updateAbsence,
  rejectAbsence,
} from "actions/absences";

import { ReactComponent as HolidayIcon } from "icons/holiday.svg";
import absence_kind_options from "utils/select_options/absence_kind_options";
import getUsersOptions from "utils/select_options/getUsersOptions";

const InputField = (props) => <Input {...props} />;
const TextAreaField = (props) => <Textarea {...props} />;

const AbsenceEdit = ({
  current_user,
  absence,
  getAbsence,
  updateAbsence,
  deleteAbsence,
  rejectAbsence,
  pristine,
  change,
  handleSubmit,
  match: { params },
  history,
}) => {
  const {
    isFetching: is_fetching_users,
    data: users,
  } = useQuery("users", getUsersOptions);
  const [
    is_open_delete_modal,
    handleOpenDeleteModal,
  ] = useState(false);
  useEffect(() => {
    getAbsence(params.id);
  }, []);
  if (
    absence.status === "invalid" ||
    absence.status === "loading"
  )
    return <PageLoader />;

  if (absence.status === "failure") {
    return <p>Error</p>;
  }
  const submit = (values) =>
    updateAbsence(params.id, values)
      .then(() => history.push("/absences"))
      .catch((err) => {
        throw new SubmissionError(err);
      });

  return (
    <>
      {is_open_delete_modal && (
        <ModalDelete
          name={absence?.data?.name}
          handleClose={() => handleOpenDeleteModal(false)}
          handleDelete={() => {
            deleteAbsence(params.id).then(() => {
              handleOpenDeleteModal(false);
              history.push("/absences");
            });
          }}
        />
      )}
      <Main
        page={{
          name: "Edytuj nieobecność",
          icon: <HolidayIcon />,
          breadcrumbs: [
            { name: "Nieobecności", path: "/absences" },
            { name: "Edytuj nieobecność" },
          ],
          buttons: (
            <>
              {current_user?.is_admin &&
                absence?.data?.is_accepted && (
                  <Button
                    type="button"
                    text="Odrzuć nieobecność"
                    onClick={() => rejectAbsence(params.id)}
                  />
                )}
              <Button
                type="delete"
                text="Usuń nieobecność"
                onClick={() => handleOpenDeleteModal(true)}
              />
            </>
          ),
        }}>
        <div className="dashboard-wrapper-flex">
          <div className="column-half">
            <Dashboard>
              <form
                noValidate
                onSubmit={handleSubmit(submit)}>
                <div className="dashboard__form__submit">
                  {current_user.is_admin && (
                    <>
                      <Field
                        name="user_id"
                        type="hidden"
                        component={InputField}
                      />
                      <Select
                        options={users}
                        isLoading={is_fetching_users}
                        label="Użytkownik"
                        defaultValue={
                          absence?.data?.user
                            ? {
                                label: `${absence.data.user.first_name} ${absence.data.user.last_name}`,
                                value: absence.data.user.id,
                              }
                            : {}
                        }
                        onChange={(value) =>
                          change("user_id", value)
                        }
                      />
                    </>
                  )}
                  <Field
                    name="date_from"
                    type="date"
                    label="Data rozpoczęcia"
                    change={change}
                    component={(props) => (
                      <DatePicker {...props} />
                    )}
                  />
                  <Field
                    name="date_to"
                    type="date"
                    label="Data zakończenia"
                    change={change}
                    component={(props) => (
                      <DatePicker {...props} />
                    )}
                  />
                  <Field
                    name="kind"
                    type="hidden"
                    component={InputField}
                  />
                  <Select
                    options={absence_kind_options}
                    defaultValue={absence_kind_options?.find(
                      ({ value }) =>
                        value === absence?.data?.kind
                    )}
                    label="Rodzaj"
                    onChange={(value) =>
                      change("kind", value)
                    }
                  />
                  <Field
                    name="reason"
                    label="Powód"
                    component={TextAreaField}
                  />
                </div>
                <div className="dashboard__form__footer">
                  <Button
                    type="cancel"
                    onClick={() =>
                      history.push("/absences")
                    }
                  />
                  <Button
                    disabled={pristine}
                    type="save"
                    onClick={handleSubmit(submit)}
                  />
                </div>
              </form>
            </Dashboard>
          </div>
        </div>
      </Main>
    </>
  );
};

const mapStateToProps = (state) => ({
  current_user: state.current_user,
  absence: state.absence,
  initialValues: {
    user_id: state.absence?.data?.user?.id,
    reason: state.absence?.data?.reason,
    kind: state.absence?.data?.kind,
    date_from: state.absence?.data?.date_from
      ? new Date(state.absence.data.date_from)
      : "",
    date_to: state.absence?.data?.date_to
      ? new Date(state.absence.data.date_to)
      : "",
  },
});

const mapDispatchToProps = (dispatch) => ({
  getAbsence: (id) => dispatch(getAbsence(id)),
  updateAbsence: (id, data) =>
    dispatch(updateAbsence(id, data)),
  deleteAbsence: (id) => dispatch(deleteAbsence(id)),
  rejectAbsence: (id) => dispatch(rejectAbsence(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "edit-time-record",
    enableReinitialize: true,
  })(AbsenceEdit)
);
