import { Absences } from "pages";

const absence_routes = [
  {
    path: "/absences",
    component: Absences.List,
  },
  {
    path: "/absences/new",
    component: Absences.New,
  },
  {
    path: "/absences/:id",
    component: Absences.Edit,
  },
];

export default absence_routes;
