import { client } from "utils/api";

const getClients = () =>
  new Promise((resolve, reject) =>
    client
      .get(`/clients?per_page=100&page=1`)
      .then(({ data: { data } }) => {
        resolve(data.map(({ id, attributes: { name } }) => ({ label: name, value: id })));
      })
      .catch(err => {
        console.log(err);
        reject([]);
      })
  );

export default getClients;
