import styled from "styled-components";

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10.5px 30px;
  min-height: 60px;
  border-bottom: 1px solid #ebedf2;
  .title-wrapper {
    display: flex;
    align-items: center;
    svg {
      margin-right: 10px;
      path[fill],
      circle[fill] {
        fill: #5d78ff;
      }
    }
    .title {
      font-size: 15px;
      line-height: 20px;
      font-weight: 500;
      color: #48465b;
    }
  }
  .box-buttons {
    display: flex;
    align-items: center;
    a,
    button {
      margin-left: 15px;
    }
  }
`;
export default StyledHeader;
