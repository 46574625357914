import moment from "moment";
import { toastr } from "react-redux-toastr";
import {
  client,
  apiFormatter,
  handleErrors,
  stringifyQuery,
} from "utils/api";

export const toggleFilters = () => (dispatch) => {
  dispatch({ type: "ACCEPTED_ABSENCES_TOGGLE_FILTERS" });
};

export const toggleUnAcceptedAbsences = () => (
  dispatch
) => {
  dispatch({ type: "UNACCEPTED_ABSENCES_TOGGLE_FILTERS" });
};

const checkIfDateStartIsBiggerThanToday = (date) =>
  new Promise((resolve, reject) => {
    const today = moment().set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    const date_to = moment(date).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    const diff_in_days = date_to.diff(today, "days");
    if (diff_in_days < 2) {
      reject({
        date_from: `Minimalna data: ${today
          .add(2, "days")
          .format("DD-MM-YYYY")}`,
      });
    } else {
      resolve();
    }
  });

export const filterAbsences = (query) => async (
  dispatch
) => {
  dispatch({
    type: "ACCEPTED_ABSENCES_SET_FILTERS_QUERY",
    query,
  });
};

export const filterUnAcceptedAbsences = (query) => async (
  dispatch
) => {
  dispatch({
    type: "UNACCEPTED_ABSENCES_SET_FILTERS_QUERY",
    query,
  });
  dispatch({
    type: "UNACCEPTED_ABSENCES_FILTER_BY_QUERY",
  });
};

export const getAcceptedAbsences = ({
  page = 1,
  per_page = 50,
  ...rest
}) => (dispatch, getState) => {
  const {
    status,
    filters: { query },
  } = getState().accepted_absences;

  status === "invalid"
    ? dispatch({ type: "ACCEPTED_ABSENCES_LOADING" })
    : dispatch({ type: "ACCEPTED_ABSENCES_SEARCHING" });
  client
    .get(
      `/absences?include=user.department,approver&${stringifyQuery(
        {
          ...rest,
          page,
          per_page,
          ...query,
        }
      )}`
    )
    .then(({ data }) => {
      dispatch({
        type: "ACCEPTED_ABSENCES_SUCCESS",
        data: apiFormatter(data),
        meta: data.meta,
      });
    })
    .catch(() => {
      dispatch({ type: "ACCEPTED_ABSENCES_FAILURE" });
      toastr.error(
        "Błąd",
        "Wystąpił błąd podczas pobierania"
      );
    });
};

export const getUnAcceptedAbsences = () => async (
  dispatch
) => {
  try {
    dispatch({ type: "UNACCEPTED_ABSENCES_LOADING" });
    const { data } = await client.get(
      `/absences?unaccepted=true&include=user.department`
    );
    dispatch({
      type: "UNACCEPTED_ABSENCES_SUCCESS",
      data: apiFormatter(data),
    });
  } catch (error) {
    dispatch({ type: "UNACCEPTED_ABSENCES_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas pobierania"
    );
  }
};

export const getAbsence = (id) => async (dispatch) => {
  dispatch({ type: "ABSENCE_LOADING" });
  await client
    .get(`/absences/${id}?include=user`)
    .then(({ data }) => {
      dispatch({
        type: "ABSENCE_SUCCESS",
        data: apiFormatter(data),
      });
    })
    .catch(() => {
      dispatch({ type: "ABSENCE_FAILURE" });
      toastr.error(
        "Błąd",
        "Wystąpił błąd podzczas pobierania"
      );
    });
};

export const createAbsence = (values) => (
  dispatch,
  getState
) =>
  new Promise(async (resolve, reject) => {
    const { current_user } = getState();
    try {
      if (values.date_from && !current_user?.is_admin) {
        await checkIfDateStartIsBiggerThanToday(
          values.date_from
        );
      }

      const { data } = await client.post(
        `/absences`,
        values
      );
      toastr.success(
        "Sukces",
        "Nieobecność została dodana"
      );
      resolve(data?.data?.id);
    } catch (error) {
      toastr.error(
        "Błąd",
        "Wystąpił błąd podzczas dodawania nieobecności"
      );
      reject(
        error?.response?.data?.errors
          ? handleErrors(error.response.data.errors)
          : error
      );
    }
  });

export const updateAbsence = (id, values) => (
  dispatch,
  getState
) => {
  return new Promise(async (resolve, reject) => {
    const { current_user, absence } = getState();
    if (
      absence?.data?.is_accepted &&
      !current_user?.is_admin
    ) {
      toastr.error(
        "Błąd",
        "Nieobecność potwierdzona. Nie można już jej edytować."
      );

      reject({});
      return;
    }
    try {
      if (values.date_from && !current_user?.is_admin) {
        await checkIfDateStartIsBiggerThanToday(
          values.date_from
        );
      }
      const { data } = await client.put(
        `/absences/${id}`,
        values
      );
      toastr.success(
        "Sukces",
        "Nieobecność została zaktualizowana"
      );
      dispatch({
        type: "ABSENCE_SUCCESS",
        data: apiFormatter(data),
      });
      resolve();
    } catch (error) {
      toastr.error(
        "Błąd",
        "Wystąpił błąd podzczas aktualizowania nieobecności"
      );
      reject(
        error?.response?.data?.errors
          ? handleErrors(error.response.data.errors)
          : error
      );
    }
  });
};

export const deleteAbsence = (id) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    await client
      .delete(`/absences/${id}`)
      .then(() => {
        toastr.success(
          "Sukces",
          "Nieobecność została usunięta"
        );
        resolve();
      })
      .catch((err) => {
        toastr.error(
          "Błąd",
          "Wystąpił błąd podzczas usuwania nieobecności"
        );
        reject(handleErrors(err.response.data.errors));
      });
  });
};

export const resetAbsenceEdit = () => async (dispatch) => {
  dispatch({ type: "ABSENCE_RESET" });
};

export const acceptAbsence = (id) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      await client.post(`/absences/${id}/accept`);
      toastr.success(
        "Sukces",
        "Nieobecność została potwierdzona"
      );
      dispatch({
        type: "UNACCEPTED_ABSENCE_APPROVED",
        id,
      });
      dispatch({
        type: "UNACCEPTED_ABSENCES_FILTER_BY_QUERY",
      });
      dispatch(getAcceptedAbsences({}));
      resolve();
    } catch (error) {
      reject(error);
      toastr.error(
        "Błąd",
        "Wystąpił błąd podzczas potwierdzania nieobecności"
      );
    }
  });

export const rejectAbsence = (id) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.post(
        `/absences/${id}/reject`
      );
      toastr.success(
        "Sukces",
        "Nieobecność została odrzucona"
      );
      dispatch({
        type: "ABSENCE_REJECTED",
        data: apiFormatter(data),
      });
      resolve();
    } catch (error) {
      reject(error);
      toastr.error(
        "Błąd",
        "Wystąpił błąd podzczas odrzucania nieobecności"
      );
    }
  });
