import { client, apiFormatter } from "utils/api";

const getPendingTimeRecords = async () => {
  try {
    const { data } = await client.get(`/time_records/pending?include=user&per_page=200`);
    return apiFormatter(data);
  } catch (error) {
    throw error;
  }
};

export default getPendingTimeRecords;
