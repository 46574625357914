import { Users } from "pages";

const user_routes = [
  {
    path: "/users",
    component: Users.List,
  },
  {
    path: "/users/:id",
    component: Users.Edit,
  },
];

export default user_routes;
