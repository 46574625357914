import React from "react";
import { Link } from "react-router-dom";
import { PagePanel } from "expano-components";

const app = "board";

const Main = ({ children, page }) => {
  return (
    <div className="main-dashboard">
      <PagePanel app={app} Link={Link} data={page} />
      <div className="main-dashboard__container">
        {children}
      </div>
    </div>
  );
};

export default Main;
