import { toastr } from "react-redux-toastr";
import {
  client,
  apiFormatter,
  handleErrors,
  stringifyQuery,
} from "utils/api";
import queryString from "query-string";

export const toggleFilters = () => (dispatch) => {
  dispatch({ type: "TASKS_TOGGLE_FILTERS" });
};

export const filterTasks = (query) => async (dispatch) => {
  dispatch({ type: "TASKS_SET_FILTERS_QUERY", query });
};

export const getTasks = ({
  page = 1,
  per_page = 50,
  ...rest
}) => (dispatch, getState) => {
  const {
    status,
    filters: { query },
  } = getState().tasks;
  status === "invalid"
    ? dispatch({ type: "TASKS_LOADING" })
    : dispatch({ type: "TASKS_SEARCHING" });
  client
    .get(
      `/tasks?include=user,project.client&${stringifyQuery({
        ...rest,
        page,
        per_page,
        ...query,
      })}`
    )
    .then(({ data }) => {
      dispatch({
        type: "TASKS_SUCCESS",
        data: apiFormatter(data),
        meta: data.meta,
      });
    })
    .catch(() => {
      dispatch({ type: "TASKS_FAILURE" });
      toastr.error(
        "Błąd",
        "Wystąpił błąd podczas pobierania"
      );
    });
};

export const getTask = (id) => async (dispatch) => {
  dispatch({ type: "TASK_LOADING" });
  await client
    .get(`/tasks/${id}?include=user,project.client`)
    .then(({ data }) => {
      dispatch({
        type: "TASK_SUCCESS",
        data: apiFormatter(data),
      });
    })
    .catch(() => {
      dispatch({ type: "TASK_FAILURE" });
      toastr.error(
        "Błąd",
        "Wystąpił błąd podzczas pobierania"
      );
    });
};

export const createTask = (values) =>
  new Promise(async (resolve, reject) => {
    await client
      .post(`/tasks`, values)
      .then(({ data }) => {
        toastr.success("Sukces", "Zadanie zostało dodane");
        resolve(data?.data?.id);
      })
      .catch((err) => {
        toastr.error(
          "Błąd",
          "Wystąpił błąd podzczas dodawania zadania"
        );
        reject(handleErrors(err.response.data.errors));
      });
  });

export const updateTask = (id, values) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    await client
      .put(
        `/tasks/${id}?include=user,project.client`,
        values
      )
      .then(({ data }) => {
        toastr.success(
          "Sukces",
          "Zadanie zostało zaktualizowane"
        );
        dispatch({
          type: "TASK_SUCCESS",
          data: apiFormatter(data),
        });
        resolve();
      })
      .catch((err) => {
        toastr.error(
          "Błąd",
          "Wystąpił błąd podzczas aktualizowania zadania"
        );
        reject(handleErrors(err.response.data.errors));
      });
  });
};

export const deleteTask = (id) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    await client
      .delete(`/tasks/${id}`)
      .then(({ data }) => {
        toastr.success(
          "Sukces",
          "Zadanie zostało ousunięte"
        );
        resolve();
      })
      .catch((err) => {
        toastr.error(
          "Błąd",
          "Wystąpił błąd podzczas usuwania zadania"
        );
        reject(handleErrors(err.response.data.errors));
      });
  });
};

export const resetTaskEdit = () => async (dispatch) => {
  dispatch({ type: "TASK_RESET" });
};

export const exportToCsvk = (values) =>
  new Promise((resolve, reject) => {
    const query = queryString.stringify(values);
    client
      .get(
        `/tasks/generate_report_csv?${query}`,
        {},
        { headers: { responseType: "blob" } }
      )
      .then(({ data }) => {
        const blob = new Blob([data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        resolve();
        window.open(url);
      })
      .catch((err) => {
        toastr.error(
          "Błąd",
          err.response.data.error ||
            "Eksport sie nie powiódł"
        );
        reject();
      });
  });
