import React from "react";
import moment from "moment";
import { useQuery } from "react-query";
import { connect } from "react-redux";
import { Button, Checkbox, Input } from "expano-components";
import { Main, Dashboard, Select, DatePicker } from "components";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { createTimeRecord } from "actions/time_records";
import getUsers from "./const/getUsers";

import { ReactComponent as ClockIcon } from "icons/clock.svg";

const InputField = (props) => <Input {...props} />;
const CheckField = (props) => <Checkbox {...props} />;

const TimeRecordNew = ({
  current_user,
  handleSubmit,
  change,
  history,
}) => {
  const { isFetching: is_fetching_users, data: users } = useQuery(
    "users",
    getUsers
  );

  const submit = (values) =>
    createTimeRecord(values)
      .then(() => history.push("/time_records"))
      .catch((err) => {
        throw new SubmissionError(err);
      });

  return (
    <Main
      page={{
        name: "Dodaj czas pracy",
        icon: <ClockIcon />,
        breadcrumbs: [
          { name: "Czasy pracy", path: "/time_records" },
          { name: "Dodaj czas pracy" },
        ],
      }}
    >
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <Dashboard>
            <form noValidate onSubmit={handleSubmit(submit)}>
              <div className="dashboard__form__submit">
                {current_user.is_admin && (
                  <>
                    <Field
                      name="user_id"
                      type="hidden"
                      component={InputField}
                    />
                    <Select
                      options={users}
                      isLoading={is_fetching_users}
                      label="Użytkownik"
                      initValue={
                        current_user
                          ? {
                              label: `${current_user.first_name} ${current_user?.last_name}`,
                              value: current_user.id,
                            }
                          : {}
                      }
                      onChange={(value) => change("user_id", value)}
                    />
                  </>
                )}
                <Field
                  name="time_from"
                  label="Czas rozpoczęcia"
                  change={change}
                  component={(props) => <DatePicker {...props} />}
                />
                <Field
                  name="remote_work"
                  label="Praca zdalna"
                  component={CheckField}
                />
              </div>
              <div className="dashboard__form__footer">
                <Button
                  type="cancel"
                  onClick={() => history.push("/time_records")}
                />
                <Button
                  type="save"
                  onClick={handleSubmit(submit)}
                />
              </div>
            </form>
          </Dashboard>
        </div>
      </div>
    </Main>
  );
};

export default connect((state) => ({
  current_user: state.current_user,
  initialValues: {
    user_id: state.current_user.id,
    time_from: moment().toDate(),
    remote_work: false,
  },
}))(
  reduxForm({
    form: "new-time-record",
    enableReinitialize: false,
  })(TimeRecordNew)
);
