import React from "react";
import {
  QueryClient,
  QueryClientProvider,
} from "react-query";
import { connect } from "react-redux";
import {
  Link,
  withRouter,
  Redirect,
  Switch,
  Route,
} from "react-router-dom";
import { handleLogout } from "actions/account";
import { toggleSidebar } from "actions/sidebar";
import { TopBar, Sidebar } from "expano-components";
import nav from "./nav";
import { NotFound } from "pages";

import time_record_routes from "./routes/time_record";
import task_routes from "./routes/task";
import absence_routes from "./routes/absence";

import user_routes from "./routes/user";
import client_routes from "./routes/client";
import project_routes from "./routes/project";
import department_routes from "./routes/department";
import financial_reports_routes from "./routes/financial_reports";

const queryClient = new QueryClient();

const app = "board";

const public_routes = ({
  is_admin,
  has_access_to_financial_reports,
}) => [
  {
    path: "/",
    component: () => <Redirect to="/time_records" />,
  },
  ...time_record_routes,
  ...task_routes,
  ...absence_routes,
  ...(is_admin || has_access_to_financial_reports
    ? financial_reports_routes
    : []),
];

const private_routes = [
  ...user_routes,
  ...client_routes,
  ...project_routes,
  ...department_routes,
];

const ProtectedView = ({
  current_user,
  sidebar,
  toggleSidebar,
  history,
  location,
}) => {
  const {
    is_admin,
    has_access_to_financial_reports,
    email,
    company,
  } = current_user;
  const routes = is_admin
    ? [
        ...public_routes({
          is_admin,
          has_access_to_financial_reports,
        }),
        ...private_routes,
      ]
    : [
        ...public_routes({
          is_admin,
          has_access_to_financial_reports,
        }),
      ];

  return (
    <QueryClientProvider client={queryClient}>
      <Sidebar
        app={app}
        Link={Link}
        location={location}
        nav={nav({
          is_admin,
          has_access_to_financial_reports,
        })}
        is_open={sidebar.is_open}
      />
      <main className="main-container">
        <TopBar
          handleLogout={handleLogout}
          is_open={sidebar.is_open}
          toggleSidebar={toggleSidebar}
          history={history}
          currentUser={{ email, company: company?.name }}
        />
        <Switch>
          {routes?.map(({ path, component }, index) => (
            <Route
              key={index}
              exact={true}
              path={path}
              component={component}
            />
          ))}
          <Route component={NotFound} />
        </Switch>
      </main>
    </QueryClientProvider>
  );
};
const mapDispatchToProps = (dispatch) => ({
  toggleSidebar: () => dispatch(toggleSidebar()),
});

export default connect(
  ({ sidebar, current_user }) => ({
    sidebar,
    current_user,
  }),
  mapDispatchToProps
)(withRouter(ProtectedView));
