import moment from "moment";

const initialState = {
  status: "invalid",
  filters: {
    open: true,
    query: {
      "q[date_from_gteq]": moment().format("YYYY-MM-01"),
      "q[date_from_lt]": moment()
        .add(1, "months")
        .format("YYYY-MM-DD"),
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "ACCEPTED_ABSENCES_TOGGLE_FILTERS":
      return {
        ...state,
        filters: { ...state.filters, open: !state.filters.open },
      };
    case "ACCEPTED_ABSENCES_SET_FILTERS_QUERY":
      return {
        ...state,
        filters: { ...state.filters, query: action.query },
      };
    case "ACCEPTED_ABSENCES_SEARCHING":
      return {
        ...state,
        status: "searching",
      };
    case "ACCEPTED_ABSENCES_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "ACCEPTED_ABSENCES_SUCCESS":
      return {
        ...state,
        status: "success",
        ...action,
      };
    case "ACCEPTED_ABSENCES_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    default:
      return state;
  }
};
