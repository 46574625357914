import { client } from "utils/api";

const getDepartmentsOptions = () =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        data: { data },
      } = await client.get(`/departments?per_page=100`);
      const options = data.map(
        ({ id, attributes: { name } }) => ({
          label: name,
          value: id,
        })
      );
      resolve(options);
    } catch (error) {
      console.log(error);
      reject([]);
    }
  });

export default getDepartmentsOptions;
