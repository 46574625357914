import React from "react";
import ReactMarkdownEditor from "react-markdown-editor-lite";

const Editor = ({ label, meta, input, rest }) => {
  return (
    <div
      className={`form-group ${meta.touched && meta.valid ? "visited" : ""} ${
        meta.touched && meta.invalid ? "error" : ""
      }`}
    >
      <div className='inp-wrapper'>
        {label && (
          <label htmlFor={input.name} className='label-form'>
            {label}
          </label>
        )}
        <ReactMarkdownEditor
          plugins={["header", "fonts", "link"]}
          config={{
            view: {
              menu: true,
              md: true,
              html: false,
              fullScreen: false,
              hideMenu: false,
            },
          }}
          style={{ height: "280px" }}
          {...input}
          onChange={el => input.onChange(el.text)}
        />
      </div>

      {meta.touched && meta.invalid && meta.error && <p className='inp-error'>{meta.error}</p>}
    </div>
  );
};

export default Editor;
