import React, { useState } from "react";
import { connect } from "react-redux";
import { Main, Button } from "components";

import GenerateTimeRecordsReportModal from "./components/GenerateTimeRecordsReportModal";
import CompletedTimeRecordsList from "./components/Completed";

import { ReactComponent as ClockIcon } from "icons/clock.svg";
import Pending from "./components/Pending";

const TimeRecordsList = ({ current_user }) => {
  const [is_open_generate_report_modal, handleOpenGenerateReportModal] =
    useState(false);
  return (
    <Main
      page={{
        name: "Czas pracy",
        icon: <ClockIcon />,
        breadcrumbs: [{ name: "Czas pracy" }],
        buttons: (
          <>
            {current_user?.is_admin && (
              <button
                type="button"
                className="btn btn-lg btn-green"
                onClick={() => handleOpenGenerateReportModal(true)}
              >
                <span>Generuj raport</span>
              </button>
            )}
            <Button
              type="add"
              text="Dodaj czas pracy"
              path="/time_records/new"
            />
          </>
        ),
      }}
    >
      {current_user?.is_admin && is_open_generate_report_modal && (
        <GenerateTimeRecordsReportModal
          handleClose={() => handleOpenGenerateReportModal(false)}
        />
      )}
      <Pending />
      <CompletedTimeRecordsList />
    </Main>
  );
};

export default connect(({ current_user }) => ({
  current_user,
}))(TimeRecordsList);
