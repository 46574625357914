import { toastr } from "react-redux-toastr";
import {
  client,
  apiFormatter,
  handleErrors,
  stringifyQuery,
} from "utils/api";

export const toggleFilters = () => (dispatch) => {
  dispatch({ type: "DEPARTMENTS_TOGGLE_FILTERS" });
};

export const filterDepartments = (query) => async (
  dispatch
) => {
  dispatch({
    type: "DEPARTMENTS_SET_FILTERS_QUERY",
    query,
  });
};

export const getDepartments = ({
  page = 1,
  per_page = 10,
  ...rest
}) => (dispatch, getState) => {
  const {
    status,
    filters: { query },
  } = getState().departments;
  status === "invalid"
    ? dispatch({ type: "DEPARTMENTS_LOADING" })
    : dispatch({ type: "DEPARTMENTS_SEARCHING" });
  client
    .get(
      `/departments?${stringifyQuery({
        ...rest,
        page,
        per_page,
        ...query,
      })}`
    )
    .then(({ data }) => {
      dispatch({
        type: "DEPARTMENTS_SUCCESS",
        data: apiFormatter(data),
        meta: data.meta,
      });
    })
    .catch(() => {
      dispatch({ type: "DEPARTMENTS_FAILURE" });
      toastr.error(
        "Błąd",
        "Wystąpił błąd podczas pobierania"
      );
    });
};

export const getDepartment = (id) => async (dispatch) => {
  dispatch({ type: "DEPARTMENT_LOADING" });
  await client
    .get(`/departments/${id}?include=users`)
    .then(({ data }) => {
      dispatch({
        type: "DEPARTMENT_SUCCESS",
        data: apiFormatter(data),
      });
    })
    .catch(() => {
      dispatch({ type: "DEPARTMENT_FAILURE" });
      toastr.error(
        "Błąd",
        "Wystąpił błąd podzczas pobierania"
      );
    });
};

export const createDepartment = (values) => {
  return new Promise(async (resolve, reject) => {
    await client
      .post(`/departments`, values)
      .then(({ data }) => {
        resolve(data?.data?.id);
        toastr.success("Sukces", "Dział został utworzony");
      })
      .catch((err) => {
        toastr.error(
          "Błąd",
          "Wystąpił błąd podzczas tworzenia działu"
        );
        reject(handleErrors(err.response.data.errors));
      });
  });
};

export const updateDepartment = (id, values) => (
  dispatch
) => {
  return new Promise(async (resolve, reject) => {
    await client
      .put(`/departments/${id}?include=users`, values)
      .then(({ data }) => {
        toastr.success(
          "Sukces",
          "Dział został zaktualizowany"
        );
        dispatch({
          type: "DEPARTMENT_SUCCESS",
          data: apiFormatter(data),
        });
        resolve();
      })
      .catch((err) => {
        toastr.error(
          "Błąd",
          "Wystąpił błąd podzczas aktualizowania działu"
        );
        reject(handleErrors(err.response.data.errors));
      });
  });
};

export const deleteDepartment = (id) =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/departments/${id}`);
      toastr.success("Sukces", "Dział został usunięty");
      resolve();
    } catch (error) {
      toastr.error(
        "Błąd",
        "Wystąpił błąd podzczas usuwania działu"
      );
      reject(handleErrors(error.response.data.errors));
    }
  });

export const resetDepartmentEdit = () => async (
  dispatch
) => {
  dispatch({ type: "DEPARTMENT_RESET" });
};

export const addUserToDepartment = ({
  department_id,
  user_id,
}) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.put(
        `/users/${user_id}`,
        { department_id }
      );
      toastr.success(
        "Sukces",
        "Użytkownik został przypisany do działu"
      );
      dispatch({
        type: "ADD_USER_TO_DEPARTMENT",
        data: apiFormatter(data),
      });
      resolve();
    } catch (error) {
      toastr.error(
        "Błąd",
        "Wystąpił błąd podzczas przypisywania użytkownika"
      );
      reject(handleErrors(error.response.data.errors));
    }
  });
};
export const removeUserFromDepartment = (user_id) => (
  dispatch
) => {
  return new Promise(async (resolve, reject) => {
    try {
      await client.put(`/users/${user_id}`, {
        department_id: null,
      });
      toastr.success(
        "Sukces",
        "Użytkownik został usunięty z działu"
      );
      dispatch({
        type: "REMOVE_USER_TO_DEPARTMENT",
        user_id,
      });
      resolve();
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd");
      reject(handleErrors(error.response.data.errors));
    }
  });
};
