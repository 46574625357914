import React from "react";
import { connect } from "react-redux";
import { Button, Input } from "expano-components";
import { Dashboard } from "components";
import { updateDepartment } from "actions/departments";
import {
  Field,
  reduxForm,
  SubmissionError,
} from "redux-form";


const InputField = (props) => <Input {...props} />;

const BasicData = ({
  department,
  updateDepartment,
  pristine,
  handleSubmit,
  history,
}) => {
  const onSubmit = (values) =>
    updateDepartment(department.id, values)
      .then(() => history.push(`/departments`))
      .catch((err) => {
        throw new SubmissionError(err);
      });
  return (
    <div className="column-half">
      <Dashboard
        header={{
          title: "Podstawowe dane",
          icon: "info",
        }}
        footer={{
          buttons: (
            <>
              <Button
                type="cancel"
                onClick={() => history.push("/departments")}
              />
              <Button
                key="submit"
                disabled={pristine}
                type="save"
                onClick={handleSubmit(onSubmit)}
              />
            </>
          ),
        }}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <div className="dashboard__form__submit">
            <Field
              name="name"
              label="Nazwa"
              component={InputField}
            />
          </div>
        </form>
      </Dashboard>
    </div>
  );
};

const mapStateToProps = ({ department }) => ({
  department: department?.data,
  initialValues: {
    name: department?.data?.name,
  },
});

const mapDispatchToProps = (dispatch) => ({
  updateDepartment: (id, data) =>
    dispatch(updateDepartment(id, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "edit-department",
    enableReinitialize: true,
  })(BasicData)
);
