import React from "react";
import { useQuery } from "react-query";
import { connect } from "react-redux";
import { Button, Input, Textarea } from "expano-components";
import {
  Main,
  Dashboard,
  Select,
  DatePicker,
} from "components";
import {
  Field,
  reduxForm,
  SubmissionError,
} from "redux-form";
import { createAbsence } from "actions/absences";
import getUsersOptions from "utils/select_options/getUsersOptions";
import absence_kind_options from "utils/select_options/absence_kind_options";

import { ReactComponent as HolidayIcon } from "icons/holiday.svg";

const InputField = (props) => <Input {...props} />;
const TextAreaField = (props) => <Textarea {...props} />;

const AbsenceNew = ({
  current_user,
  pristine,
  handleSubmit,
  change,
  history,
  createAbsence,
  initialValues,
}) => {
  const {
    isFetching: is_fetching_users,
    data: users,
  } = useQuery("users", getUsersOptions);

  const submit = (values) =>
    createAbsence(values)
      .then(() => history.push("/absences"))
      .catch((err) => {
        console.log(err);
        throw new SubmissionError(err);
      });
  return (
    <Main
      page={{
        name: "Dodaj nieobecność",
        icon: <HolidayIcon />,
        breadcrumbs: [
          { name: "Nieobecności", path: "/absences" },
          { name: "Dodaj nieobecność" },
        ],
      }}>
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <Dashboard>
            <form
              noValidate
              onSubmit={handleSubmit(submit)}>
              <div className="dashboard__form__submit">
                {current_user.is_admin && (
                  <>
                    <Field
                      name="user_id"
                      type="hidden"
                      component={InputField}
                    />
                    <Select
                      options={users}
                      isLoading={is_fetching_users}
                      label="Użytkownik"
                      initValue={
                        current_user
                          ? {
                              label: `${current_user.first_name} ${current_user?.last_name}`,
                              value: current_user.id,
                            }
                          : {}
                      }
                      onChange={(value) => {
                        change("user_id", value);
                      }}
                    />
                  </>
                )}
                <Field
                  name="date_from"
                  type="date"
                  label="Data rozpoczęcia"
                  change={change}
                  component={(props) => (
                    <DatePicker {...props} />
                  )}
                />
                <Field
                  name="date_to"
                  type="date"
                  label="Data zakończenia"
                  change={change}
                  component={(props) => (
                    <DatePicker {...props} />
                  )}
                />
                <Field
                  name="kind"
                  type="hidden"
                  component={InputField}
                />
                <Select
                  options={absence_kind_options}
                  label="Rodzaj"
                  onChange={(value) =>
                    change("kind", value)
                  }
                  defaultValue={absence_kind_options?.find(
                    ({ value }) =>
                      value === initialValues?.kind
                  )}
                />
                <Field
                  name="reason"
                  label="Powód"
                  component={TextAreaField}
                />
              </div>
              <div className="dashboard__form__footer">
                <Button
                  type="cancel"
                  onClick={() => history.push("/absences")}
                />
                <Button
                  disabled={pristine}
                  type="save"
                  onClick={handleSubmit(submit)}
                />
              </div>
            </form>
          </Dashboard>
        </div>
      </div>
    </Main>
  );
};

export default connect(
  (state) => ({
    current_user: state.current_user,
    initialValues: {
      user_id: state.current_user.id,
      kind: "vacation",
    },
  }),
  (dispatch) => ({
    createAbsence: (values) =>
      dispatch(createAbsence(values)),
  })
)(
  reduxForm({
    form: "new-time-record",
    enableReinitialize: true,
  })(AbsenceNew)
);
