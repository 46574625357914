import React from "react";
import { Watch } from "react-loader-spinner";

import StyledLoading from "./styles";

const Loading = () => {
  return (
    <StyledLoading>
      <Watch color="#2959F8" height={40} width={40} />
    </StyledLoading>
  );
};

export default Loading;
