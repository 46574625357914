import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useQuery } from "react-query";
import {
  Field,
  reduxForm,
  SubmissionError,
  formValueSelector,
} from "redux-form";
import {
  Button,
  Checkbox,
  Input,
  ModalDelete,
  Textarea,
} from "expano-components";
import { Main, Dashboard, PageLoader, Select, DatePicker } from "components";
import {
  getTimeRecord,
  deleteTimeRecord,
  updateTimeRecord,
} from "actions/time_records";
import { secondsToHms } from "utils/helpers";
import getUsers from "./const/getUsers";

import { ReactComponent as ClockIcon } from "icons/clock.svg";

const selector = formValueSelector("edit-time-record");

const InputField = (props) => <Input {...props} />;
const TextAreaField = (props) => <Textarea {...props} />;
const CheckField = (props) => <Checkbox {...props} />;

const TimeRecordEdit = ({
  current_user,
  time_record,
  time_from,
  time_to,
  getTimeRecord,
  updateTimeRecord,
  deleteTimeRecord,
  change,
  handleSubmit,
  match: { params },
  history,
}) => {
  const [diff, setDiff] = useState(0);
  const { isFetching: is_fetching_users, data: users } = useQuery(
    "users",
    getUsers
  );
  const [is_open_delete_modal, handleOpenDeleteModal] = useState(false);

  useEffect(() => {
    getTimeRecord(params.id);
  }, []);

  useEffect(() => {
    if (time_from && time_to) {
      var duration = moment.duration(moment(time_to).diff(moment(time_from)));
      var seconds = duration.asSeconds();
      setDiff(seconds);
    }
  }, [time_from, time_to]);

  if (time_record.status === "invalid" || time_record.status === "loading")
    return <PageLoader />;

  if (time_record.status === "failure") {
    return <p>Error</p>;
  }

  const submit = (values) =>
    updateTimeRecord(params.id, values)
      .then(() => history.push("/time_records"))
      .catch((err) => {
        throw new SubmissionError(err);
      });

  return (
    <>
      {is_open_delete_modal && (
        <ModalDelete
          name="czas pracy"
          handleClose={() => handleOpenDeleteModal(false)}
          handleDelete={() => {
            deleteTimeRecord(params.id).then(() => {
              handleOpenDeleteModal(false);
              history.push("/time_records");
            });
          }}
        />
      )}
      <Main
        page={{
          name: "Edytuj czas pracy",
          icon: <ClockIcon />,
          breadcrumbs: [
            { name: "Czasy pracy", path: "/time_records" },
            { name: "Edytuj czas pracy" },
          ],
          buttons: (
            <Button
              type="delete"
              text="Usuń czas pracy"
              onClick={() => handleOpenDeleteModal(true)}
            />
          ),
        }}
      >
        <div className="dashboard-wrapper-flex">
          <div className="column-half">
            <Dashboard
              header={{
                title: "Podstawowe dane",
                icon: "info",
              }}
            >
              <form noValidate onSubmit={handleSubmit(submit)}>
                <div className="dashboard__form__submit">
                  {current_user.is_admin && (
                    <>
                      <Field
                        name="user_id"
                        type="hidden"
                        component={InputField}
                      />

                      <Select
                        options={users}
                        isLoading={is_fetching_users}
                        label="Użytkownik"
                        defaultValue={
                          time_record?.data?.user
                            ? {
                                label: `${time_record.data.user.first_name} ${time_record.data.user.last_name}`,
                                value: time_record.data.user.id,
                              }
                            : {}
                        }
                        onChange={(value) => change("user_id", value)}
                      />
                    </>
                  )}
                  <DatePicker
                    disabled
                    label="Czas rozpoczęcia"
                    input={{
                      value: time_record?.data?.time_from
                        ? moment(time_record.data.time_from).toDate()
                        : null,
                    }}
                  />
                  <Field
                    name="time_to"
                    label="Czas zakończenia"
                    change={change}
                    component={(props) => <DatePicker {...props} />}
                  />
                  <div className="form-group">
                    <div className="inp-wrapper">
                      <label htmlFor="diff" className="form-label">
                        Czas pracy
                      </label>
                      <div className="inp-group">
                        <input
                          id="diff"
                          name="diff"
                          type="text"
                          value={secondsToHms(diff)}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <Field
                    name="remote_work"
                    label="Praca zdalna"
                    component={CheckField}
                  />
                  {time_from && time_to && diff !== 8 && (
                    <Field
                      name="description"
                      label={
                        diff > 8
                          ? "Powód nadgodzin"
                          : "Powód mniejszej ilości godzin"
                      }
                      change={change}
                      component={TextAreaField}
                    />
                  )}
                </div>
                <div className="dashboard__form__footer">
                  <Button
                    type="cancel"
                    onClick={() => history.push("/time_records")}
                  />
                  <Button
                    type="save"
                    onClick={handleSubmit(submit)}
                  />
                </div>
              </form>
            </Dashboard>
          </div>
        </div>
      </Main>
    </>
  );
};

const mapStateToProps = (state) => ({
  current_user: state.current_user,
  time_record: state.time_record,
  time_from: state.time_record?.data?.time_from,
  time_to: selector(state, "time_to"),
  initialValues: {
    user_id: state.time_record?.data?.user?.id,
    description: state.time_record?.data?.description,
    remote_work: state.time_record?.data?.remote_work,
    time_to: state.time_record?.data?.time_from
      ? moment(state.time_record.data.time_from).add("8", "hours").toDate()
      : "",
  },
});

const mapDispatchToProps = (dispatch) => ({
  getTimeRecord: (id) => dispatch(getTimeRecord(id)),
  updateTimeRecord: (id, data) => dispatch(updateTimeRecord(id, data)),
  deleteTimeRecord: (id) => dispatch(deleteTimeRecord(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "edit-time-record",
    enableReinitialize: true,
  })(TimeRecordEdit)
);
