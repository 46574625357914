import { FinancialReports } from "pages";

const financial_reports_routes = [
  {
    path: "/financial_reports",
    component: FinancialReports.FinancialCharts,
  },
];

export default financial_reports_routes;
