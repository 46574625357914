const initialState = {
  status: "invalid",
  filters: {
    open: true,
    query: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "UNACCEPTED_ABSENCES_TOGGLE_FILTERS":
      return {
        ...state,
        filters: {
          ...state.filters,
          open: !state.filters.open,
        },
      };
    case "UNACCEPTED_ABSENCES_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "UNACCEPTED_ABSENCES_SUCCESS":
      return {
        ...state,
        status: "success",
        data: action.data,
        all_data: action.data,
      };
    case "UNACCEPTED_ABSENCES_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    case "UNACCEPTED_ABSENCE_APPROVED": {
      return {
        ...state,
        status: "success",
        all_data: state.all_data.filter(
          ({ id }) => id !== action.id
        ),
      };
    }
    case "UNACCEPTED_ABSENCES_SET_FILTERS_QUERY":
      return {
        ...state,
        filters: { ...state.filters, query: action.query },
      };
    case "UNACCEPTED_ABSENCES_FILTER_BY_QUERY": {
      const departments =
        state?.filters?.query?.[
          "q[user_department_id_in]"
        ] || [];
      const data_copy =
        departments?.length > 0
          ? [...state.all_data].filter(({ user }) =>
              departments.includes(user?.department?.id)
            )
          : [...state.all_data];

      return { ...state, data: data_copy };
    }
    default:
      return state;
  }
};
