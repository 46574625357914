import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useQuery } from "react-query";
import { withRouter } from "react-router";
import moment from "moment";
import { Dashboard, MainTable } from "components";
import { parseQuery } from "utils/api";
import {
  getAcceptedAbsences,
  filterAbsences,
  toggleFilters,
} from "actions/absences";

import absence_kind_options from "utils/select_options/absence_kind_options";
import getUsersOptions from "utils/select_options/getUsersOptions";

const AbsencesList = ({
  current_user,
  accepted_absences,
  toggleFilters,
  getAcceptedAbsences,
  filterAbsences,
  history,
  is_fetching_departments,
  departments,
  location: { search },
}) => {
  const query = parseQuery(search);

  const {
    isFetching: is_fetching_users,
    data: users,
  } = useQuery("users", getUsersOptions, {
    enabled: current_user?.is_admin,
  });

  const [is_loaded, setLoaded] = useState(false);
  const sort = {
    "sort[column]": query["sort[column]"] || "date_from",
    "sort[order]": query["sort[order]"] || "desc",
  };

  useEffect(() => {
    getAcceptedAbsences({ ...query, ...sort });
    setLoaded(true);
  }, [search]);

  useEffect(() => {
    if (is_loaded) {
      getAcceptedAbsences({ ...query, page: undefined });
    }
  }, [accepted_absences.filters.query]);

  if (accepted_absences.status === "failure") {
    return <p>Error</p>;
  }

  const { data, meta } = accepted_absences;

  return (
    <Dashboard
      header={{ title: "Zaakceptowane nieobecności" }}
    >
      <MainTable
        is_loading={
          accepted_absences.status === "invalid" ||
          accepted_absences.status === "loading"
        }
        is_searching={
          accepted_absences.status === "searching"
        }
        filters_open={accepted_absences.filters.open}
        toggleFilters={toggleFilters}
        data={data}
        meta={meta}
        sort={sort}
        head={[
          {
            label: "Imię i nazwisko",
            name: "user",
            sortable: false,
            style: { width: "200px" },
          },
          {
            label: "Data od",
            name: "date_from",
            style: { width: "150px" },
          },
          {
            label: "Data do",
            name: "date_to",
            style: { width: "150px" },
          },
          {
            label: "Rodzaj",
            name: "kind",
            style: { width: "150px" },
          },
          {
            label: "Dział",
            name: "department",
            style: { width: "150px" },
          },
          {
            label: "Powód",
            name: "reason",
            sortable: false,
            style: { width: "200px" },
          },
          {
            label: "Zaakceptowane przez",
            name: "is_accepted",
            style: { width: "130px" },
          },
        ]}
        empty_text="Brak nieobecności"
        renderRow={({
          id,
          user,
          date_from,
          date_to,
          reason,
          kind,
          approver,
        }) => (
          <tr
            key={id}
            style={{ cursor: "pointer" }}
            onMouseDown={(event) => {
              if (event.button === 1) {
                window.open(`/absences/${id}`, "_blank");
              }
            }}
            onClick={() => history.push(`/absences/${id}`)}
          >
            <td>
              {user?.first_name} {user?.last_name}
            </td>
            <td>
              {moment(date_from).format("DD.MM.YYYY")}
            </td>
            <td>{moment(date_to).format("DD.MM.YYYY")}</td>
            <td>
              {absence_kind_options.find(
                ({ value }) => value === kind
              )?.label || "-"}
            </td>
            <td>{user?.department?.name || "-"}</td>
            <td>{reason || "-"}</td>
            <td>{approver?.full_name || "-"}</td>
          </tr>
        )}
        filtersAction={filterAbsences}
        filters={[
          current_user.is_admin && {
            type: "multi-select",
            label: "Użytkownik",
            search_type: "in",
            options: users,
            is_loading: is_fetching_users,
            name: "user_id",
          },
          current_user.is_admin && {
            type: "multi-select",
            label: "Dział",
            search_type: "in",
            options: departments,
            is_loading: is_fetching_departments,
            name: "user_department_id",
          },
          {
            type: "month",
            search_type: "month_date",
            label: "Miesiąc",
            name: "month",
            default_value: [
              moment()
                .startOf("month")
                .toDate(),
            ],
          },
        ].filter(Boolean)}
      />
    </Dashboard>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getAcceptedAbsences: (data) =>
    dispatch(getAcceptedAbsences(data)),
  filterAbsences: (data) => dispatch(filterAbsences(data)),
  toggleFilters: () => dispatch(toggleFilters()),
});

export default connect(
  ({ current_user, accepted_absences }) => ({
    current_user,
    accepted_absences,
  }),
  mapDispatchToProps
)(withRouter(AbsencesList));
