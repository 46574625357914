import { toastr } from "react-redux-toastr";
import {
  client,
  apiFormatter,
  handleErrors,
  stringifyQuery,
} from "utils/api";

export const toggleFilters = () => (dispatch) => {
  dispatch({ type: "USERS_TOGGLE_FILTERS" });
};

export const filterUsers = (query) => (dispatch) => {
  dispatch({ type: "USERS_SET_FILTERS_QUERY", query });
};

export const getUser = (id) => async (dispatch) => {
  dispatch({ type: "USER_LOADING" });
  await client
    .get(`/users/${id}?include=department`)
    .then(({ data }) => {
      dispatch({
        type: "USER_SUCCESS",
        data: apiFormatter(data),
      });
    })
    .catch(() => {
      dispatch({ type: "USER_FAILURE" });
      toastr.error(
        "Błąd",
        "Wystąpił błąd podzczas pobierania"
      );
    });
};

export const getUsers = ({
  page = 1,
  per_page = 10,
  ...rest
}) => (dispatch, getState) => {
  const {
    status,
    filters: { query },
  } = getState().users;
  status === "invalid"
    ? dispatch({ type: "USERS_LOADING" })
    : dispatch({ type: "USERS_SEARCHING" });
  client
    .get(
      `/users?include=department&${stringifyQuery({
        ...rest,
        page,
        per_page,
        ...query,
      })}`
    )
    .then(({ data }) => {
      dispatch({
        type: "USERS_SUCCESS",
        data: apiFormatter(data),
        meta: data.meta,
      });
    })
    .catch(() => {
      dispatch({ type: "USERS_FAILURE" });
      toastr.error(
        "Błąd",
        "Wystąpił błąd podczas pobierania"
      );
    });
};

export const updateUser = (
  id,
  {
    is_wrong_hours_alert_enabled,
    has_access_to_financial_reports,
    department_id,
  }
) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    await client
      .put(`/users/${id}`, {
        is_wrong_hours_alert_enabled,
        has_access_to_financial_reports,
        department_id,
      })
      .then(({ data }) => {
        toastr.success(
          "Sukces",
          "Użytkownik został zaktualizowany"
        );
        dispatch({
          type: "USER_SUCCESS",
          data: apiFormatter(data),
        });
        resolve();
      })
      .catch((err) => {
        toastr.error(
          "Błąd",
          "Wystąpił błąd podzczas aktualizowania użytkownika"
        );
        reject(handleErrors(err.response.data.errors));
      });
  });
};
