import { Projects } from "pages";

const project_routes = [
  {
    path: "/projects",
    component: Projects.List,
  },
  {
    path: "/projects/new",
    component: Projects.New,
  },
  {
    path: "/projects/:id",
    component: Projects.Edit,
  },
];

export default project_routes;
