import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useQuery } from "react-query";
import ReactMarkdown from "react-markdown";
import moment from "moment";
import {
  Dashboard,
  MainTable,
  Main,
  Button,
} from "components";
import { parseQuery } from "utils/api";
import {
  getTasks,
  filterTasks,
  toggleFilters,
} from "actions/tasks";
import getProjects from "../const/getProjects";
import getUsers from "../const/getUsers";
import getClients from "../const/getClients";
import GenerateTaskaReportModal from "./components/GenerateTaskaReportModal";
import { secondsToHms } from "utils/helpers";

import getDepartmentsOptions from "utils/select_options/getDepartmentsOptions";

import { ReactComponent as TaskIcon } from "icons/task.svg";

const TasksList = ({
  current_user,
  tasks,
  toggleFilters,
  getTasks,
  filterTasks,
  history,
  location: { search },
}) => {
  const query = parseQuery(search);
  const [is_loaded, setLoaded] = useState(false);
  const [
    is_open_generate_report_modal,
    handleOpenGenerateReportModal,
  ] = useState(false);
  const {
    isFetching: is_fetching_users,
    data: users,
  } = useQuery("users", getUsers);
  const {
    isFetching: is_fetching_projects,
    data: projects,
  } = useQuery("projects", getProjects);

  const {
    isFetching: is_fetching_departments,
    data: departments,
  } = useQuery("departments", getDepartmentsOptions, {
    enabled: current_user?.is_admin,
  });
  const {
    isFetching: is_fetching_clients,
    data: clients,
  } = useQuery("clients", getClients, {
    enabled: current_user?.is_admin,
  });

  const sort = {
    "sort[column]": query["sort[column]"] || "time_from",
    "sort[order]": query["sort[order]"] || "desc",
  };
  useEffect(() => {
    getTasks({ ...query, ...sort });
    setLoaded(true);
  }, [search]);
  useEffect(() => {
    if (is_loaded) {
      getTasks({ ...query, page: undefined });
    }
  }, [tasks.filters.query]);
  if (tasks.status === "failure") {
    return <p>Error</p>;
  }

  const { data, meta } = tasks;

  const percent_diff =
    meta?.time_total && meta?.copyright_transfer_time_total
      ? (
          (meta.copyright_transfer_time_total * 100) /
          meta.time_total
        )?.toFixed(0)
      : 0;

  return (
    <Main
      page={{
        name: "Zadania",
        icon: <TaskIcon />,
        breadcrumbs: [{ name: "Zadania" }],
        buttons: (
          <>
            {current_user?.is_admin && (
              <button
                type="button"
                className="btn btn-lg btn-green"
                onClick={() =>
                  handleOpenGenerateReportModal(true)
                }
              >
                <span>Generuj raport</span>
              </button>
            )}
            <Button
              type="add"
              text="Dodaj zadanie"
              path="/tasks/new"
            />
          </>
        ),
      }}
    >
      {current_user?.is_admin &&
        is_open_generate_report_modal && (
          <GenerateTaskaReportModal
            handleClose={() =>
              handleOpenGenerateReportModal(false)
            }
          />
        )}
      <Dashboard>
        <MainTable
          is_loading={
            tasks.status === "invalid" ||
            tasks.status === "loading"
          }
          is_searching={tasks.status === "searching"}
          filters_open={tasks.filters.open}
          toggleFilters={toggleFilters}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            current_user.is_admin && {
              label: "Imię i nazwisko",
              name: "user_name",
              sortable: false,
              style: { width: 200 },
            },
            {
              label: "Projekt",
              name: "project_name",
              sortable: false,
              style: { width: 170 },
            },
            {
              label: "Opis",
              name: "description",
              sortable: false,
            },
            {
              label: "Praca nad utworem",
              name: "with_copyright_transfer",
              style: { width: 170 },
            },
            {
              label: "Czas rozpoczęcia",
              name: "time_from",
              style: { width: 170 },
            },
            {
              label: "Czas zakończenia",
              name: "time_to",
              style: { width: 170 },
            },
            {
              label: "Czas pracy",
              name: "time_total",
              style: { width: 150 },
            },
          ].filter(Boolean)}
          empty_text="Brak tasków"
          renderRow={({
            id,
            project,
            user,
            time_to,
            time_from,
            time_total,
            description,
            with_copyright_transfer,
            copyright_transfer_percent,
          }) => (
            <tr
              key={id}
              style={{ cursor: "pointer" }}
              onMouseDown={(event) => {
                if (event.button === 1) {
                  window.open(`/tasks/${id}`, "_blank");
                }
              }}
              onClick={() => history.push(`/tasks/${id}`)}
            >
              {current_user.is_admin && (
                <td>
                  {user?.first_name} {user?.last_name}
                </td>
              )}
              <td>
                {project?.client?.name || "-"} /{" "}
                {project?.name || "-"}
              </td>
              <td>
                {(
                  <ReactMarkdown>
                    {description}
                  </ReactMarkdown>
                ) || "-"}
              </td>
              <td>
                {with_copyright_transfer
                  ? `Tak (${copyright_transfer_percent}%)`
                  : "Nie"}
              </td>
              <td>
                {moment(time_from).format(
                  "DD.MM.YYYY HH:mm"
                )}
              </td>
              <td>
                {moment(time_to).format("DD.MM.YYYY HH:mm")}
              </td>
              <td>{secondsToHms(time_total)}</td>
            </tr>
          )}
          filtersAction={filterTasks}
          filters={[
            current_user.is_admin && {
              type: "multi-select",
              label: "Użytkownik",
              search_type: "in",
              options: users,
              is_loading: is_fetching_users,
              name: "user_id",
            },
            {
              type: "month",
              search_type: "month",
              label: "Miesiąc",
              name: "month",
              default_value: [
                moment()
                  .startOf("month")
                  .toDate(),
              ],
            },
            {
              type: "multi-select",
              label: "Projekt",
              search_type: "in",
              options: projects,
              is_loading: is_fetching_projects,
              name: "project_id",
            },
            {
              type: "text_group",
              name: "text",
              data: [
                {
                  label: "Łączny czas pracy:",
                  name: "time_total",
                  value: meta?.time_total
                    ? secondsToHms(meta.time_total)
                    : 0,
                },
                {
                  label: "Czas pracy nad utworem",
                  name: "copyright_transfer_time_total",
                  value: `${
                    meta?.copyright_transfer_time_total
                      ? secondsToHms(
                          meta.copyright_transfer_time_total
                        )
                      : 0
                  } (${percent_diff}%)`,
                },
              ],
            },
            current_user.is_admin && {
              type: "multi-select",
              label: "Dział",
              search_type: "in",
              options: departments,
              is_loading: is_fetching_departments,
              name: "user_department_id",
            },
            current_user.is_admin && {
              type: "multi-select",
              label: "Klient",
              search_type: "in",
              options: clients,
              is_loading: is_fetching_clients,
              name: "project_client_id",
            },
          ].filter(Boolean)}
        />
      </Dashboard>
    </Main>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getTasks: (data) => dispatch(getTasks(data)),
  filterTasks: (data) => dispatch(filterTasks(data)),
  toggleFilters: () => dispatch(toggleFilters()),
});
export default connect(
  ({ current_user, tasks }) => ({ current_user, tasks }),
  mapDispatchToProps
)(TasksList);
