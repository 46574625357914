import Jsona from "jsona";
import queryString from "query-string";
import axios from "axios";

const dataFormatter = new Jsona();
export const url =
  process.env.NODE_ENV === "production"
    ? "https://api.board.tradesk.pl"
    : "http://0.0.0.0:3000";

export const api_url = `${url}/v1`;

export let client = null;

export const createAxios = (token) => {
  axios.defaults.headers.common["Authorization"] = token;
  client = axios.create({ baseURL: api_url });
};

export const apiFormatter = (data) => {
  return dataFormatter.deserialize(data);
};

export const handleErrors = (data) => {
  try {
    const errors = {};
    data.map(
      ({ detail, source: { pointer } }) =>
        (errors[
          pointer.split("/")[pointer.split("/").length - 1]
        ] = detail)
    );
    return errors;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const parseQuery = (data) =>
  queryString.parse(data, { arrayFormat: "bracket" });
export const stringifyQuery = (data) =>
  queryString.stringify(data, { arrayFormat: "bracket" });
