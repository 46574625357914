import { Clients } from "pages";

const client_routes = [
  {
    path: "/clients",
    component: Clients.List,
  },
  {
    path: "/clients/new",
    component: Clients.New,
  },
  {
    path: "/clients/:id",
    component: Clients.Edit,
  },
];

export default client_routes;
