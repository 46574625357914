import { Departments } from "pages";

const department_routes = [
  {
    path: "/departments",
    component: Departments.List,
  },
  {
    path: "/departments/new",
    component: Departments.New,
  },
  {
    path: "/departments/:id",
    component: Departments.Edit,
  },
];

export default department_routes;
