import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Button, Input } from "expano-components";
import {
  Main,
  Dashboard,
  PageLoader,
  Select,
} from "components";
import queryString from "query-string";
import {
  Field,
  reduxForm,
  SubmissionError,
} from "redux-form";
import { createProject } from "actions/projects";
import getClients from "./const/getClients";
import { client } from "utils/api";
import { ReactComponent as ProjectIcon } from "icons/project.svg";

const InputField = (props) => <Input {...props} />;

const ProjectNew = ({
  pristine,
  handleSubmit,
  change,
  history,
  location: { search },
}) => {
  const [is_loaded, setLoaded] = useState(false);
  const [default_client, setDefaultClient] = useState({});
  const { client_id } = queryString.parse(search);
  const {
    isFetching: is_fetching_clients,
    data: clients,
  } = useQuery("clients", getClients);

  const submit = (values) =>
    createProject(values)
      .then(() =>
        history.push(
          `/projects${client_id ? `/${client_id}` : ""}`
        )
      )
      .catch((err) => {
        throw new SubmissionError(err);
      });

  const getClient = async () => {
    try {
      const {
        data: {
          data: { id, attributes },
        },
      } = await client.get(`/clients/${client_id}`);

      setDefaultClient({
        label: `${attributes.name}`,
        value: id,
      });
      change("client_id", id);
      setLoaded(true);
    } catch (error) {
      console.log(error);
      setLoaded(true);
    }
  };
  useEffect(() => {
    if (client_id) {
      getClient();
    } else {
      setLoaded(true);
    }
  }, []);
  if (!is_loaded) return <PageLoader />;
  return (
    <Main
      page={{
        name: "Dodaj projekt",
        icon: <ProjectIcon />,
        breadcrumbs: [
          { name: "Projekty", path: "/projects" },
          { name: "Dodaj projekt" },
        ],
      }}>
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <Dashboard>
            <form
              noValidate
              onSubmit={handleSubmit(submit)}>
              <div className="dashboard__form__submit">
                <Field
                  name="name"
                  label="Nazwa"
                  component={InputField}
                />
                <Field
                  name="client_id"
                  type="hidden"
                  component={InputField}
                />
                <Select
                  isLoading={is_fetching_clients}
                  options={clients}
                  label="Klient"
                  defaultValue={default_client}
                  onChange={(value) =>
                    change("client_id", value)
                  }
                />
              </div>
              <div className="dashboard__form__footer">
                <Button
                  type="cancel"
                  onClick={() => history.push("/tasks")}
                />
                <Button
                  disabled={pristine}
                  type="save"
                  onClick={handleSubmit(submit)}
                />
              </div>
            </form>
          </Dashboard>
        </div>
      </div>
    </Main>
  );
};

export default reduxForm({
  form: "new-project",
  enableReinitialize: true,
})(ProjectNew);
