import React from "react";
import { connect } from "react-redux";
import { Button, Input } from "expano-components";
import { Main, Dashboard } from "components";
import { createClient } from "actions/clients";
import {
  Field,
  reduxForm,
  SubmissionError,
} from "redux-form";

import { ReactComponent as ClientIcon } from "icons/client.svg";

const InputField = (props) => <Input {...props} />;

const ClientNew = ({ pristine, handleSubmit, history }) => {
  const submit = (values) =>
    createClient(values)
      .then(() => history.push(`/clients`))
      .catch((err) => {
        throw new SubmissionError(err);
      });

  return (
    <Main
      page={{
        name: "Dodaj klienta",
        icon: <ClientIcon />,
        breadcrumbs: [
          { name: "Klienci", path: "/clients" },
          { name: "Dodaj klienta" },
        ],
      }}>
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <Dashboard>
            <form
              noValidate
              onSubmit={handleSubmit(submit)}>
              <div className="dashboard__form__submit">
                <Field
                  name="name"
                  label="Nazwa"
                  component={InputField}
                />
                <Field
                  name="nip"
                  label="Nip"
                  component={InputField}
                />
                <Field
                  name="address"
                  label="Adres"
                  component={InputField}
                />
              </div>
              <div className="dashboard__form__footer">
                <Button
                  type="cancel"
                  onClick={() => history.push("/clients")}
                />
                <Button
                  disabled={pristine}
                  type="save"
                  onClick={handleSubmit(submit)}
                />
              </div>
            </form>
          </Dashboard>
        </div>
      </div>
    </Main>
  );
};

export default connect(null)(
  reduxForm({
    form: "new-client",
    enableReinitialize: true,
  })(ClientNew)
);
