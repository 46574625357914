import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  Dashboard,
  MainTable,
  Main,
  Button,
} from "components";
import { parseQuery } from "utils/api";
import {
  getClients,
  filterClients,
  toggleFilters,
} from "actions/clients";

import { ReactComponent as ClientIcon } from "icons/client.svg";

const ClientsList = ({
  clients,
  toggleFilters,
  getClients,
  filterClients,
  history,
  location: { search },
}) => {
  const query = parseQuery(search);
  const [is_loaded, setLoaded] = useState(false);
  const sort = {
    "sort[column]": query["sort[column]"] || "id",
    "sort[order]": query["sort[order]"] || "asc",
  };
  useEffect(() => {
    getClients({ ...query, ...sort });
    setLoaded(true);
  }, [search]);
  useEffect(() => {
    if (is_loaded) {
      getClients({ ...query, page: undefined });
    }
  }, [clients.filters.query]);
  if (clients.status === "failure") {
    return <p>Error</p>;
  }
  const { data, meta } = clients;
  return (
    <Main
      page={{
        name: "Klienci",
        icon: <ClientIcon />,
        breadcrumbs: [{ name: "Klienci" }],
        buttons: (
          <Button
            type="add"
            text="Dodaj klienta"
            path="/clients/new"
          />
        ),
      }}>
      <Dashboard>
        <MainTable
          is_loading={
            clients.status === "invalid" ||
            clients.status === "loading"
          }
          is_searching={clients.status === "searching"}
          filters_open={clients.filters.open}
          toggleFilters={toggleFilters}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            { label: "ID", name: "id" },
            { label: "Nazwa", name: "name" },
            { label: "Nip", name: "nip" },
            { label: "Adres", name: "address" },
          ]}
          empty_text="Brak klientów"
          renderRow={({ id, name, nip, address }) => (
            <tr
              key={id}
              style={{ cursor: "pointer" }}
              onMouseDown={(event) => {
                if (event.button === 1) {
                  window.open(`/clients/${id}`, "_blank");
                }
              }}
              onClick={() =>
                history.push(`/clients/${id}`)
              }>
              <td>{id}</td>
              <td>{name}</td>
              <td>{nip || "-"}</td>
              <td>{address || "-"}</td>
            </tr>
          )}
          filtersAction={filterClients}
          filters={[
            {
              type: "input",
              label: "Nazwa",
              name: "name",
              search_type: "matches",
            },
          ]}
        />
      </Dashboard>
    </Main>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getClients: (data) => dispatch(getClients(data)),
  filterClients: (data) => dispatch(filterClients(data)),
  toggleFilters: () => dispatch(toggleFilters()),
});
export default connect(
  ({ clients }) => ({ clients }),
  mapDispatchToProps
)(ClientsList);
