import "rc-slider/assets/index.css";
import Slider from "rc-slider";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import moment from "moment";
import { connect } from "react-redux";
import { Button, Input, Checkbox } from "expano-components";
import {
  Main,
  Dashboard,
  Editor,
  Select,
  DatePicker,
} from "components";
import {
  Field,
  reduxForm,
  SubmissionError,
  formValueSelector,
} from "redux-form";
import { createTask } from "actions/tasks";
import { secondsToHms } from "utils/helpers";
import getUsers from "./const/getUsers";
import getProjects from "./const/getProjects";

import { ReactComponent as TaskIcon } from "icons/task.svg";

const selector = formValueSelector("new-task");

const CheckField = (props) => <Checkbox {...props} />;
const InputField = (props) => <Input {...props} />;
const EditorField = (props) => <Editor {...props} />;

const TaskNew = ({
  current_user,
  time_from,
  time_to,
  pristine,
  handleSubmit,
  change,
  history,
  with_copyright_transfer,
  copyright_transfer_percent,
}) => {
  const [diff, setDiff] = useState(0);
  const submit = (values) => {
    const values_data = { ...values };
    if (!with_copyright_transfer) {
      Object.assign(values_data, {
        copyright_transfer_percent: 0,
      });
    }
    return createTask(values_data)
      .then(() => history.push(`/tasks`))
      .catch((err) => {
        throw new SubmissionError(err);
      });
  };

  useEffect(() => {
    if (time_from && time_to) {
      var duration = moment.duration(
        moment(time_to).diff(moment(time_from))
      );
      var seconds = duration.asSeconds();
      setDiff(seconds);
    }
  }, [time_from, time_to]);

  const {
    isFetching: is_fetching_users,
    data: users,
  } = useQuery("users", getUsers);
  const {
    isFetching: is_fetching_projects,
    data: projects,
  } = useQuery("projects", getProjects);

  return (
    <Main
      page={{
        name: "Dodaj zadanie",
        icon: <TaskIcon />,
        breadcrumbs: [
          { name: "Zadania", path: "/tasks" },
          { name: "Dodaj zadanie" },
        ],
      }}>
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <Dashboard>
            <form
              noValidate
              onSubmit={handleSubmit(submit)}>
              <div className="dashboard__form__submit">
                {current_user.is_admin && (
                  <>
                    <Field
                      name="user_id"
                      type="hidden"
                      component={InputField}
                    />
                    <Select
                      options={users}
                      isLoading={is_fetching_users}
                      label="Użytkownik"
                      initValue={
                        current_user
                          ? {
                              label: `${current_user.first_name} ${current_user?.last_name}`,
                              value: current_user.id,
                            }
                          : {}
                      }
                      onChange={(value) => {
                        change("user_id", value);
                      }}
                    />
                  </>
                )}
                <Field
                  name="project_id"
                  type="hidden"
                  component={InputField}
                />
                <Select
                  options={projects}
                  isLoading={is_fetching_projects}
                  label="Projekt"
                  onChange={(value) => {
                    change("project_id", value);
                  }}
                />
                <Field
                  label="Opis"
                  component={EditorField}
                  name="description"
                />
                <Field
                  name="time_from"
                  label="Czas rozpoczęcia"
                  change={change}
                  component={(props) => (
                    <DatePicker {...props} />
                  )}
                />
                <Field
                  name="time_to"
                  label="Czas zakończenia"
                  change={change}
                  component={(props) => (
                    <DatePicker {...props} />
                  )}
                />
                <div className="form-group">
                  <div className="inp-wrapper">
                    <label
                      htmlFor="diff"
                      className="form-label">
                      Czas pracy
                    </label>
                    <div className="inp-group">
                      <input
                        id="diff"
                        name="diff"
                        type="text"
                        value={secondsToHms(diff)}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <Field
                  name="with_copyright_transfer"
                  label="Praca nad utworem z przekazaniem praw autorskich"
                  component={CheckField}
                />
                {with_copyright_transfer && (
                  <div className="form-group">
                    <div className="inp-wrapper">
                      <label
                        htmlFor="diff"
                        className="form-label">
                        Procentowa wartość pracy nad utworem
                      </label>
                      <div className="inp-group">
                        <input
                          style={{ width: 45 }}
                          id="diff"
                          name="diff"
                          type="text"
                          value={copyright_transfer_percent}
                          readOnly
                        />
                        <span className="form-span postfix">
                          %
                        </span>
                      </div>
                    </div>
                    <Slider
                    step={5}
                      style={{ marginTop: 10, width: 300 }}
                      defaultValue={
                        copyright_transfer_percent
                      }
                      onChange={(value) =>
                        change(
                          "copyright_transfer_percent",
                          value
                        )
                      }
                    />
                  </div>
                )}
              </div>
              <div className="dashboard__form__footer">
                <Button
                  type="cancel"
                  onClick={() =>
                    history.push("/time_records")
                  }
                />
                <Button
                  disabled={pristine}
                  type="save"
                  onClick={handleSubmit(submit)}
                />
              </div>
            </form>
          </Dashboard>
        </div>
      </div>
    </Main>
  );
};

export default connect((state) => ({
  current_user: state.current_user,
  time_from: selector(state, "time_from"),
  time_to: selector(state, "time_to"),
  with_copyright_transfer: selector(
    state,
    "with_copyright_transfer"
  ),
  copyright_transfer_percent: selector(
    state,
    "copyright_transfer_percent"
  ),
  initialValues: {
    user_id: state.current_user.id,
    time_from: new Date(
      moment().format("YYYY-MM-DD 00:00")
    ),
    time_to: new Date(moment().format("YYYY-MM-DD 00:00")),
    with_copyright_transfer: false,
    copyright_transfer_percent: 80,
  },
}))(
  reduxForm({ form: "new-task", enableReinitialize: true })(
    TaskNew
  )
);
