import React from "react";
import { connect } from "react-redux";
import { Button, Input } from "expano-components";
import { Main, Dashboard } from "components";
import { createDepartment } from "actions/departments";
import {
  Field,
  reduxForm,
  SubmissionError,
} from "redux-form";

import { ReactComponent as DepartmentIcon } from "icons/client.svg";

const InputField = (props) => <Input {...props} />;

const DepartmentNew = ({
  pristine,
  handleSubmit,
  history,
}) => {
  const submit = (values) =>
    createDepartment(values)
      .then(() => history.push(`/departments`))
      .catch((err) => {
        throw new SubmissionError(err);
      });

  return (
    <Main
      page={{
        name: "Dodaj dział",
        icon: <DepartmentIcon />,
        breadcrumbs: [
          { name: "Działy", path: "/departments" },
          { name: "Dodaj dział" },
        ],
      }}>
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <Dashboard>
            <form
              noValidate
              onSubmit={handleSubmit(submit)}>
              <div className="dashboard__form__submit">
                <Field
                  name="name"
                  label="Nazwa"
                  component={InputField}
                />
              </div>
              <div className="dashboard__form__footer">
                <Button
                  type="cancel"
                  onClick={() =>
                    history.push("/departments")
                  }
                />
                <Button
                  disabled={pristine}
                  type="save"
                  onClick={handleSubmit(submit)}
                />
              </div>
            </form>
          </Dashboard>
        </div>
      </div>
    </Main>
  );
};

export default connect(null)(
  reduxForm({
    form: "new-client",
    enableReinitialize: true,
  })(DepartmentNew)
);
