import { client, apiFormatter } from "utils/api";

const getProjects = () =>
  new Promise((resolve, reject) =>
    client
      .get(`/projects?include=client&per_page=100&page=1`)
      .then(({ data }) => {
        resolve(
          apiFormatter(data).map(({ id, name, client }) => ({
            label: `${client?.name} / ${name}`,
            value: id,
          }))
        );
      })
      .catch(err => {
        console.log(err);
        reject([]);
      })
  );

export default getProjects;
