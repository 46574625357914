import { toastr } from "react-redux-toastr";
import {
  client,
  apiFormatter,
  handleErrors,
  stringifyQuery,
} from "utils/api";
import queryString from "query-string";

export const toggleFilters = () => (dispatch) => {
  dispatch({ type: "TIME_RECORDS_TOGGLE_FILTERS" });
};

export const filterTimeRecords = (query) => async (
  dispatch
) => {
  dispatch({
    type: "TIME_RECORDS_SET_FILTERS_QUERY",
    query,
  });
};

export const getTimeRecords = ({
  page = 1,
  per_page = 50,
  ...rest
}) => (dispatch, getState) => {
  const {
    status,
    filters: { query },
  } = getState().time_records;
  status === "invalid"
    ? dispatch({ type: "TIME_RECORDS_LOADING" })
    : dispatch({ type: "TIME_RECORDS_SEARCHING" });
  client
    .get(
      `/time_records?include=user&${stringifyQuery({
        ...rest,
        page,
        per_page,
        ...query,
      })}`
    )
    .then(({ data }) => {
      dispatch({
        type: "TIME_RECORDS_SUCCESS",
        data: apiFormatter(data),
        meta: data.meta,
      });
    })
    .catch(() => {
      dispatch({ type: "TIME_RECORDS_FAILURE" });
      toastr.error(
        "Błąd",
        "Wystąpił błąd podczas pobierania"
      );
    });
};

export const getTimeRecord = (id) => async (dispatch) => {
  dispatch({ type: "TIME_RECORD_LOADING" });
  await client
    .get(`/time_records/${id}?include=user`)
    .then(({ data }) => {
      dispatch({
        type: "TIME_RECORD_SUCCESS",
        data: apiFormatter(data),
      });
    })
    .catch(() => {
      dispatch({ type: "TIME_RECORD_FAILURE" });
      toastr.error(
        "Błąd",
        "Wystąpił błąd podzczas pobierania"
      );
    });
};

export const createTimeRecord = (values) =>
  new Promise(async (resolve, reject) => {
    await client
      .post(`/time_records`, values)
      .then(({ data }) => {
        toastr.success(
          "Sukces",
          "Czas pracy został dodany"
        );
        resolve(data?.data?.id);
      })
      .catch((err) => {
        toastr.error(
          "Błąd",
          "Wystąpił błąd podzczas dodawania czasu pracy"
        );
        reject(handleErrors(err.response.data.errors));
      });
  });

export const updateTimeRecord = (id, values) => (
  dispatch
) => {
  return new Promise(async (resolve, reject) => {
    await client
      .put(`/time_records/${id}`, values)
      .then(({ data }) => {
        toastr.success(
          "Sukces",
          "Czas pracy został zaktualizowany"
        );
        dispatch({
          type: "TIME_RECORD_SUCCESS",
          data: apiFormatter(data),
        });
        resolve();
      })
      .catch((err) => {
        toastr.error(
          "Błąd",
          "Wystąpił błąd podzczas aktualizowania czasu pracy"
        );
        reject(handleErrors(err.response.data.errors));
      });
  });
};

export const deleteTimeRecord = (id) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    await client
      .delete(`/time_records/${id}`)
      .then(({ data }) => {
        toastr.success(
          "Sukces",
          "Czas pracy został usunięty"
        );
        resolve();
      })
      .catch((err) => {
        toastr.error(
          "Błąd",
          "Wystąpił błąd podzczas usuwania czasu pracy"
        );
        reject(handleErrors(err.response.data.errors));
      });
  });
};

export const resetTimeRecordEdit = () => async (
  dispatch
) => {
  dispatch({ type: "TIME_RECORD_RESET" });
};

export const exportToCsvk = (values) =>
  new Promise((resolve, reject) => {
    const query = queryString.stringify(values);
    client
      .get(
        `/time_records/generate_report_csv?${query}`,
        {},
        { headers: { responseType: "blob" } }
      )
      .then(({ data }) => {
        const blob = new Blob([data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        resolve();
        window.open(url);
      })
      .catch((err) => {
        toastr.error(
          "Błąd",
          err.response.data.error ||
            "Eksport sie nie powiódł"
        );
        reject();
      });
  });
