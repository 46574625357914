import "rc-slider/assets/index.css";
import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { connect } from "react-redux";
import moment from "moment";
import {
  Field,
  reduxForm,
  SubmissionError,
  formValueSelector,
} from "redux-form";
import {
  Button,
  Input,
  Checkbox,
  ModalDelete,
} from "expano-components";
import {
  Main,
  Dashboard,
  PageLoader,
  Editor,
  Select,
  DatePicker,
} from "components";
import {
  getTask,
  deleteTask,
  updateTask,
} from "actions/tasks";
import { secondsToHms } from "utils/helpers";
import getUsers from "./const/getUsers";
import getProjects from "./const/getProjects";

import { ReactComponent as TaskIcon } from "icons/task.svg";
import Slider from "rc-slider";

const selector = formValueSelector("edit-task");

const CheckField = (props) => <Checkbox {...props} />;
const InputField = (props) => <Input {...props} />;
const EditorField = (props) => <Editor {...props} />;

const TaskEdit = ({
  current_user,
  task,
  time_from,
  time_to,
  getTask,
  updateTask,
  deleteTask,
  pristine,
  change,
  handleSubmit,
  match: { params },
  history,
  with_copyright_transfer,
  copyright_transfer_percent,
}) => {
  const [diff, setDiff] = useState(0);
  const [
    is_open_delete_modal,
    handleOpenDeleteModal,
  ] = useState(false);

  useEffect(() => {
    getTask(params.id);
  }, []);

  useEffect(() => {
    if (time_from && time_to) {
      var duration = moment.duration(
        moment(time_to).diff(moment(time_from))
      );
      var seconds = duration.asSeconds();
      setDiff(seconds);
    }
  }, [time_from, time_to]);

  const {
    isFetching: is_fetching_users,
    data: users,
  } = useQuery("users", getUsers);

  const {
    isFetching: is_fetching_projects,
    data: projects,
  } = useQuery("projects", getProjects);

  if (
    !task ||
    task.status === "invalid" ||
    task.status === "loading"
  )
    return <PageLoader />;

  if (task.status === "failure") {
    return <p>Error</p>;
  }

  const submit = (values) => {
    const values_data = { ...values };

    if (!with_copyright_transfer) {
      Object.assign(values_data, {
        copyright_transfer_percent: 0,
      });
    }
    return updateTask(params.id, values_data)
      .then(() => history.push(`/tasks`))
      .catch((err) => {
        throw new SubmissionError(err);
      });
  };

  return (
    <>
      {is_open_delete_modal && (
        <ModalDelete
          name={task?.data?.name}
          handleClose={() => handleOpenDeleteModal(false)}
          handleDelete={() => {
            deleteTask(params.id).then(() => {
              handleOpenDeleteModal(false);
              history.push("/tasks");
            });
          }}
        />
      )}
      <Main
        page={{
          name: "Edytuj zadanie",
          icon: <TaskIcon />,
          breadcrumbs: [
            { name: "Zadania", path: "/tasks" },
            { name: "Edytuj zadanie" },
          ],
          buttons: (
            <Button
              type="delete"
              text="Usuń zadanie"
              onClick={() => handleOpenDeleteModal(true)}
            />
          ),
        }}>
        <div className="dashboard-wrapper-flex">
          <div className="column-half">
            <Dashboard
              header={{
                title: "Podstawowe dane",
                icon: "info",
              }}>
              <form
                noValidate
                onSubmit={handleSubmit(submit)}>
                <div className="dashboard__form__submit">
                  {current_user.is_admin && (
                    <>
                      <Field
                        name="user_id"
                        type="hidden"
                        component={InputField}
                      />
                      <Select
                        options={users}
                        isLoading={is_fetching_users}
                        label="Użytkownik"
                        defaultValue={
                          task?.data?.user
                            ? {
                                label: `${task.data.user.first_name} ${task.data.user.last_name}`,
                                value: task.data.user.id,
                              }
                            : {}
                        }
                        onChange={(value) =>
                          change("user_id", value)
                        }
                      />
                    </>
                  )}
                  <Field
                    name="project_id"
                    type="hidden"
                    component={InputField}
                  />
                  <Select
                    options={projects}
                    isLoading={is_fetching_projects}
                    label="Projekt"
                    defaultValue={
                      task?.data?.project
                        ? {
                            label: `${task.data.project?.client?.name} / ${task.data.project.name}`,
                            value: task.data.project.id,
                          }
                        : {}
                    }
                    onChange={(value) =>
                      change("project_id", value)
                    }
                  />
                  <Field
                    label="Opis"
                    component={EditorField}
                    name="description"
                  />
                  <Field
                    name="time_from"
                    label="Czas rozpoczęcia"
                    change={change}
                    component={(props) => (
                      <DatePicker {...props} />
                    )}
                  />
                  <Field
                    name="time_to"
                    label="Czas zakończenia"
                    change={change}
                    component={(props) => (
                      <DatePicker {...props} />
                    )}
                  />
                  <div className="form-group">
                    <div className="inp-wrapper">
                      <label
                        htmlFor="diff"
                        className="form-label">
                        Czas pracy
                      </label>
                      <div className="inp-group">
                        <input
                          id="diff"
                          name="diff"
                          type="text"
                          value={secondsToHms(diff)}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <Field
                    name="with_copyright_transfer"
                    label="Praca nad utworem z przekazaniem praw autorskich"
                    component={CheckField}
                  />
                  {with_copyright_transfer && (
                    <div className="form-group">
                      <div className="inp-wrapper">
                        <label
                          htmlFor="diff"
                          className="form-label">
                          Procentowa wartość pracy nad
                          utworem
                        </label>
                        <div className="inp-group">
                          <input
                            style={{ width: 45 }}
                            id="diff"
                            name="diff"
                            type="text"
                            value={
                              copyright_transfer_percent
                            }
                            readOnly
                          />
                          <span className="form-span postfix">
                            %
                          </span>
                        </div>
                      </div>
                      <Slider
                        step={5}
                        style={{
                          marginTop: 10,
                          width: 300,
                        }}
                        defaultValue={
                          copyright_transfer_percent
                        }
                        onChange={(value) =>
                          change(
                            "copyright_transfer_percent",
                            value
                          )
                        }
                      />
                    </div>
                  )}
                </div>
                <div className="dashboard__form__footer">
                  <Button
                    type="cancel"
                    onClick={() => history.push("/tasks")}
                  />
                  <Button
                    disabled={pristine}
                    type="save"
                    onClick={handleSubmit(submit)}
                  />
                </div>
              </form>
            </Dashboard>
          </div>
        </div>
      </Main>
    </>
  );
};

const mapStateToProps = (state) => ({
  current_user: state.current_user,
  task: state.task,
  time_from: selector(state, "time_from"),
  time_to: selector(state, "time_to"),
  with_copyright_transfer: selector(
    state,
    "with_copyright_transfer"
  ),
  copyright_transfer_percent: selector(
    state,
    "copyright_transfer_percent"
  ),
  initialValues: {
    with_copyright_transfer:
      state.task?.data?.with_copyright_transfer,
    copyright_transfer_percent:
      state.task?.data?.copyright_transfer_percent,
    description: state.task?.data?.description,
    user_id: state.task?.data?.user?.id,
    project_id: state.task?.data?.project?.id,
    time_from: state.task?.data?.time_from
      ? new Date(state.task.data.time_from)
      : "",
    time_to: state.task?.data?.time_to
      ? new Date(state.task.data.time_to)
      : "",
  },
});

const mapDispatchToProps = (dispatch) => ({
  getTask: (id) => dispatch(getTask(id)),
  updateTask: (id, data) => dispatch(updateTask(id, data)),
  deleteTask: (id) => dispatch(deleteTask(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "edit-task",
    enableReinitialize: true,
  })(TaskEdit)
);
