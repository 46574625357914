import React from "react";
import "chart.js/auto";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { Dashboard, Main, PageLoader } from "components";

import { ReactComponent as TaskIcon } from "icons/task.svg";
import { useQuery } from "react-query";
import { client } from "utils/api";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
    },
  },
  scales: {
    x: { stacked: true },
    y: { stacked: false },
  },
};

const FinancialCharts = () => {
  const {
    isFetching: is_fetching,
    data: financial_stats,
  } = useQuery({
    queryKey: "financial_stats",
    queryFn: () => client.get("/financial_results/stats"),
    select: (res) => res?.data?.data,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  if (is_fetching) {
    return <PageLoader />;
  }

  const year = new Date().getFullYear();

  const line_chart_data = Array(12)
    .fill(null)
    .map(
      () =>
        financial_stats?.corrected_last_year_avg_month_value ||
        0
    );

  const bar_chart_data = financial_stats?.total_net_income_month_histogram?.map(
    (a) => a?.[1]
  );
  const bar_chart_predicted_data = financial_stats?.total_net_income_month_histogram?.map(
    (_, i) =>
      i === new Date().getMonth()
        ? financial_stats?.predicted_value_this_month
        : 0
  );

  const labels = Array(12)
    .fill(null)
    .map(
      (_, i) =>
        `${year}-${i + 1 < 10 ? `0${i + 1}` : i + 1}-01`
    );

  const data = {
    labels,
    datasets: [
      {
        label: "Obrót miesięczny",
        backgroundColor: "rgba(93, 120, 255, 0.2)",
        borderColor: "rgb(93, 120, 255)",
        borderWidth: 1,
        type: "bar",
        data: bar_chart_data,
        stack: "stack_1",
      },
      {
        label: "Obrót miesięczny predykcja",
        backgroundColor: "rgba(60, 179, 113, 0.2)",
        borderColor: "rgb(60, 179, 113)",
        borderWidth: 1,

        data: bar_chart_predicted_data,
        stack: "stack_1",
      },
      {
        label: "Próg obrotu premiowego",
        type: "line",
        borderColor: "rgba(255, 99, 132, 1)",
        backgroundColor: "#fff",
        borderWidth: 2,
        fill: false,
        pointRadius: 0,
        pointHoverRadius: 0,
        data: line_chart_data,
      },
    ],
  };

  return (
    <Main
      page={{
        name: "Raporty finansowe",
        icon: <TaskIcon />,
        breadcrumbs: [{ name: "Raporty finansowe" }],
      }}
    >
      <Dashboard>
        <Chart
          type="bar"
          style={{ padding: 20 }}
          width={window.innerWidth - 560}
          height="350px"
          data={data}
          options={options}
        />
      </Dashboard>
    </Main>
  );
};

export default FinancialCharts;
