import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, ModalDelete } from "expano-components";
import { Main, PageLoader } from "components";
import {
  getDepartment,
  deleteDepartment,
  updateDepartment,
} from "actions/departments";

import BasicData from "./components/BasicData";
import DepartmentUsers from "./components/DepartmentUsers";

import { ReactComponent as DepartmentIcon } from "icons/department.svg";

const DepartmentEdit = ({
  department,
  getDepartment,
  match: { params },
  history,
}) => {
  const [
    is_open_delete_modal,
    handleOpenDeleteModal,
  ] = useState(false);

  useEffect(() => {
    getDepartment(params.id);
  }, []);

  if (
    department.status === "invalid" ||
    department.status === "loading"
  )
    return <PageLoader />;

  if (department.status === "failure") {
    return <p>Error</p>;
  }

  return (
    <>
      {is_open_delete_modal && (
        <ModalDelete
          name={department?.data?.name}
          handleClose={() => handleOpenDeleteModal(false)}
          handleDelete={() => {
            deleteDepartment(params.id).then(() => {
              handleOpenDeleteModal(false);
              history.push("/departments");
            });
          }}
        />
      )}
      <Main
        page={{
          name: department.data?.name,
          icon: <DepartmentIcon />,
          breadcrumbs: [
            { name: "Działy", path: "/departments" },
            { name: `${department.data?.name}` },
          ],
          buttons: (
            <Button
              type="delete"
              text="Usuń dział"
              onClick={() => handleOpenDeleteModal(true)}
            />
          ),
        }}>
        <div className="dashboard-wrapper-flex">
          <BasicData />
          <DepartmentUsers />
        </div>
      </Main>
    </>
  );
};

const mapStateToProps = ({ department }) => ({
  department,
});

const mapDispatchToProps = (dispatch) => ({
  getDepartment: (id) => dispatch(getDepartment(id)),
  updateDepartment: (id, data) =>
    dispatch(updateDepartment(id, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DepartmentEdit);
