import { Tasks } from "pages";

const task_routes = [
  {
    path: "/tasks",
    component: Tasks.List,
  },
  {
    path: "/tasks/new",
    component: Tasks.New,
  },
  {
    path: "/tasks/:id",
    component: Tasks.Edit,
  },
];

export default task_routes;
