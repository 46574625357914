import React, { useState } from "react";
import { useQuery } from "react-query";
import { Button } from "expano-components";
import { Modal, Select } from "components";
import { client, apiFormatter } from "utils/api";

import { ReactComponent as UserIcon } from "icons/user.svg";
import { ReactComponent as CloseIcon } from "icons/close.svg";
import WarningModal from "components/WarningModal";

const getUsersOptions = (department_id) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.get(
        `/users?include=department&per_page=1000`
      );
      const users = apiFormatter(data);
      resolve(
        users
          .filter(
            ({ department }) =>
              department_id !== department?.id
          )
          .map(
            ({
              id,
              first_name,
              last_name,
              department,
            }) => ({
              label: `${first_name} ${last_name}${
                department?.name
                  ? ` (${department.name})`
                  : ""
              }`,
              value: {
                id,
                department_id: department?.id,
              },
            })
          )
      );
    } catch (error) {
      console.log(error);
      reject([]);
    }
  });

const AddUserModal = ({
  handleConfirm,
  handleClose,
  department_id,
}) => {
  const {
    isFetching: is_fetching_users,
    data: users,
  } = useQuery("users", () =>
    getUsersOptions(department_id)
  );

  const [
    is_open_add_user_warning_modal,
    handleOpenAddUserWarningModal,
  ] = useState(false);

  const [user, setUser] = useState(false);

  const checkUser = () => {
    if (Boolean(user.department_id)) {
      handleOpenAddUserWarningModal(true);
    } else {
      handleConfirm(user.id);
    }
  };
  return (
    <>
      {is_open_add_user_warning_modal && (
        <WarningModal
          text="Użytkownik jest już przypisany do innego działu. Potwierdzenie spowoduje usunięcie go z obecnego działu i przypisanie do wybranego. Czy chcesz kontynuować?"
          handleConfirm={() => {
            handleOpenAddUserWarningModal(false);
            handleConfirm(user.id);
          }}
          handleClose={() =>
            handleOpenAddUserWarningModal(false)
          }
        />
      )}
      <Modal>
        <header className="popup__header">
          <div className="icon">
            <UserIcon />
          </div>
          <h2 className="dashboard__title heading">
            Dodaj użytkownika
          </h2>
          <button
            className="btn btn-icon"
            type="button"
            onClick={handleClose}>
            <CloseIcon />
          </button>
        </header>
        <div className="popup__body">
          <Select
            options={users}
            isLoading={is_fetching_users}
            label="Wybierz użytkownika"
            onChange={(value) => setUser(value)}
          />
        </div>
        <div className="popup__footer">
          <Button type="cancel" onClick={handleClose} />
          <Button
            disabled={!user?.id}
            type="save"
            text="Dodaj"
            onClick={checkUser}
          />
        </div>
      </Modal>
    </>
  );
};

export default AddUserModal;
