import { client, apiFormatter } from "utils/api";

const getUsers = () =>
  new Promise((resolve, reject) =>
    client
      .get(`/users?include=company&per_page=200`)
      .then(({ data }) => {
        const users = apiFormatter(data);
        resolve(
          users.map(({ id, first_name, last_name, company }) => ({
            label: `${first_name} ${last_name} (${company?.name || '-'})`,
            value: id,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
        reject([]);
      })
  );

export default getUsers;
