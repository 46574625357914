import { toastr } from "react-redux-toastr";
import { client, apiFormatter, handleErrors, stringifyQuery } from "utils/api";

export const toggleFilters = () => dispatch => {
  dispatch({ type: "PROJECTS_TOGGLE_FILTERS" });
};

export const filterProjects = query => async dispatch => {
  dispatch({ type: "PROJECTS_SET_FILTERS_QUERY", query });
};

export const getProjects = ({ page = 1, per_page = 10, ...rest }) => (dispatch, getState) => {
  const {
    status,
    filters: { query },
  } = getState().projects;
  status === "invalid"
    ? dispatch({ type: "PROJECTS_LOADING" })
    : dispatch({ type: "PROJECTS_SEARCHING" });
  client
    .get(`/projects?include=client&${stringifyQuery({ ...rest, page, per_page, ...query })}`)
    .then(({ data }) => {
      dispatch({
        type: "PROJECTS_SUCCESS",
        data: apiFormatter(data),
        meta: data.meta,
      });
    })
    .catch(() => {
      dispatch({ type: "PROJECTS_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
    });
};

export const getProject = id => async dispatch => {
  dispatch({ type: "PROJECT_LOADING" });
  await client
    .get(`/projects/${id}?include=client`)
    .then(({ data }) => {
      dispatch({
        type: "PROJECT_SUCCESS",
        data: apiFormatter(data),
      });
    })
    .catch(() => {
      dispatch({ type: "PROJECT_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podzczas pobierania");
    });
};

export const createProject = values =>
  new Promise(async (resolve, reject) => {
    await client
      .post(`/projects`, values)
      .then(({ data }) => {
        toastr.success("Sukces", "Projekt został utworzony");
        resolve(data?.data?.id);
      })
      .catch(err => {
        toastr.error("Błąd", "Wystąpił błąd podzczas tworzenia projektu");
        reject(handleErrors(err.response.data.errors));
      });
  });

export const updateProject = (id, values) => dispatch => {
  return new Promise(async (resolve, reject) => {
    await client
      .put(`/projects/${id}?include=client`, values)
      .then(({ data }) => {
        toastr.success("Sukces", "Projekt został zaktualizowany");
        dispatch({
          type: "PROJECT_SUCCESS",
          data: apiFormatter(data),
        });
        resolve();
      })
      .catch(err => {
        toastr.error("Błąd", "Wystąpił błąd podzczas aktualizowania projektu");
        reject(handleErrors(err.response.data.errors));
      });
  });
};

export const deleteProject = id => dispatch => {
  return new Promise(async (resolve, reject) => {
    await client
      .delete(`/projects/${id}`)
      .then(({ data }) => {
        toastr.success("Sukces", "Projekt został usunięty");
        resolve();
      })
      .catch(err => {
        toastr.error("Błąd", "Wystąpił błąd podzczas usuwania projektu");
        reject(handleErrors(err.response.data.errors));
      });
  });
};

export const resetProjectEdit = () => async dispatch => {
  dispatch({ type: "PROJECT_RESET" });
};
