import { toastr } from "react-redux-toastr";
import {
  client,
  apiFormatter,
  handleErrors,
  stringifyQuery,
} from "utils/api";

export const toggleFilters = () => (dispatch) => {
  dispatch({ type: "CLIENTS_TOGGLE_FILTERS" });
};

export const filterClients = (query) => async (
  dispatch
) => {
  dispatch({ type: "CLIENTS_SET_FILTERS_QUERY", query });
};

export const getClients = ({
  page = 1,
  per_page = 10,
  ...rest
}) => (dispatch, getState) => {
  const {
    status,
    filters: { query },
  } = getState().clients;
  status === "invalid"
    ? dispatch({ type: "CLIENTS_LOADING" })
    : dispatch({ type: "CLIENTS_SEARCHING" });
  client
    .get(
      `/clients?${stringifyQuery({
        ...rest,
        page,
        per_page,
        ...query,
      })}`
    )
    .then(({ data }) => {
      dispatch({
        type: "CLIENTS_SUCCESS",
        data: apiFormatter(data),
        meta: data.meta,
      });
    })
    .catch(() => {
      dispatch({ type: "CLIENTS_FAILURE" });
      toastr.error(
        "Błąd",
        "Wystąpił błąd podczas pobierania"
      );
    });
};

export const getClient = (id) => async (dispatch) => {
  dispatch({ type: "CLIENT_LOADING" });
  await client
    .get(`/clients/${id}?include=projects`)
    .then(({ data }) => {
      dispatch({
        type: "CLIENT_SUCCESS",
        data: apiFormatter(data),
      });
    })
    .catch(() => {
      dispatch({ type: "CLIENT_FAILURE" });
      toastr.error(
        "Błąd",
        "Wystąpił błąd podzczas pobierania"
      );
    });
};

export const createClient = (values) => {
  return new Promise(async (resolve, reject) => {
    await client
      .post(`/clients`, values)
      .then(({ data }) => {
        resolve(data?.data?.id);
        toastr.success("Sukces", "Klient został utworzony");
      })
      .catch((err) => {
        toastr.error(
          "Błąd",
          "Wystąpił błąd podzczas tworzenia klienta"
        );
        reject(handleErrors(err.response.data.errors));
      });
  });
};

export const updateClient = (id, values) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    await client
      .put(`/clients/${id}`, values)
      .then(({ data }) => {
        toastr.success(
          "Sukces",
          "Klient został zaktualizowany"
        );
        dispatch({
          type: "CLIENT_SUCCESS",
          data: apiFormatter(data),
        });
        resolve();
      })
      .catch((err) => {
        toastr.error(
          "Błąd",
          "Wystąpił błąd podzczas aktualizowania klienta"
        );
        reject(handleErrors(err.response.data.errors));
      });
  });
};

export const deleteClient = (id) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    await client
      .delete(`/clients/${id}`)
      .then(({ data }) => {
        toastr.success("Sukces", "Klient został usunięty");
        resolve();
      })
      .catch((err) => {
        toastr.error(
          "Błąd",
          "Wystąpił błąd podzczas usuwania klienta"
        );
        reject(handleErrors(err.response.data.errors));
      });
  });
};

export const resetClientEdit = () => async (dispatch) => {
  dispatch({ type: "CLIENT_RESET" });
};
