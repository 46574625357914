import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Button,
  Input,
  ModalDelete,
} from "expano-components";
import { Main, Dashboard, PageLoader } from "components";
import {
  getClient,
  deleteClient,
  updateClient,
} from "actions/clients";
import {
  Field,
  reduxForm,
  SubmissionError,
} from "redux-form";

import { ReactComponent as ClientIcon } from "icons/client.svg";

const InputField = (props) => <Input {...props} />;

const ClientEdit = ({
  client,
  getClient,
  updateClient,
  deleteClient,
  pristine,
  handleSubmit,
  match: { params },
  history,
}) => {
  const [
    is_open_delete_modal,
    handleOpenDeleteModal,
  ] = useState(false);

  useEffect(() => {
    getClient(params.id);
  }, []);

  if (
    client.status === "invalid" ||
    client.status === "loading"
  )
    return <PageLoader />;

  if (client.status === "failure") {
    return <p>Error</p>;
  }
  const submit = (values) =>
    updateClient(params.id, values)
      .then(() => history.push(`/clients`))
      .catch((err) => {
        throw new SubmissionError(err);
      });
  return (
    <>
      {is_open_delete_modal && (
        <ModalDelete
          name={client?.data?.name}
          handleClose={() => handleOpenDeleteModal(false)}
          handleDelete={() => {
            deleteClient(params.id).then(() => {
              handleOpenDeleteModal(false);
              history.push("/clients");
            });
          }}
        />
      )}
      <Main
        page={{
          name: `${client.data?.name}`,
          icon: <ClientIcon />,
          breadcrumbs: [
            { name: "Klienci", path: "/clients" },
            { name: `${client.data?.name}` },
          ],
          buttons: (
            <Button
              type="delete"
              text="Usuń klienta"
              onClick={() => handleOpenDeleteModal(true)}
            />
          ),
        }}>
        <div className="dashboard-wrapper-flex">
          <div className="column-half">
            <Dashboard
              header={{
                title: "Podstawowe dane",
                icon: "info",
              }}
              footer={{
                buttons: (
                  <>
                    <Button
                      type="cancel"
                      onClick={() =>
                        history.push("/clients")
                      }
                    />
                    <Button
                      key="submit"
                      disabled={pristine}
                      type="save"
                      onClick={handleSubmit(submit)}
                    />
                  </>
                ),
              }}>
              <form
                noValidate
                onSubmit={handleSubmit(submit)}>
                <div className="dashboard__form__submit">
                  <Field
                    name="name"
                    label="Nazwa"
                    component={InputField}
                  />
                  <Field
                    name="nip"
                    label="Nip"
                    component={InputField}
                  />
                  <Field
                    name="address"
                    label="Adres"
                    component={InputField}
                  />
                </div>
              </form>
            </Dashboard>
          </div>
          <div className="column-half">
            <Dashboard
              header={{
                title: "Projekty",
                icon: "project",
              }}
              footer={{
                buttons: (
                  <Button
                    type="add"
                    text="Dodaj projekt"
                    onClick={() =>
                      history.push(
                        `/projects/new?client_id=${client.data.id}`
                      )
                    }
                  />
                ),
              }}>
              {client?.data?.projects?.length > 0 && (
                <ul className="dashboard__list">
                  {client.data.projects.map(
                    ({ name, id }) => (
                      <li
                        className="dashboard__list-item"
                        key={id}>
                        <p>{name}</p>
                      </li>
                    )
                  )}
                </ul>
              )}
            </Dashboard>
          </div>
        </div>
      </Main>
    </>
  );
};

const mapStateToProps = ({ client }) => ({
  client,
  initialValues: {
    name: client?.data?.name,
    nip: client?.data?.nip,
    address: client?.data?.address,
  },
});

const mapDispatchToProps = (dispatch) => ({
  getClient: (id) => dispatch(getClient(id)),
  updateClient: (id, data) =>
    dispatch(updateClient(id, data)),
  deleteClient: (id) => dispatch(deleteClient(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "edit-client",
    enableReinitialize: true,
  })(ClientEdit)
);
