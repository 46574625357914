import React from "react";
import { Modal } from "components";
import { ReactComponent as DangerIcon } from "icons/danger.svg";
const WarningModal = ({
  handleClose,
  handleConfirm,
  text,
  show_close_button = true,
  cancel_button_text = "Anuluj",
  submit_button_text = "Potwierdź",
}) => (
  <Modal handleClose={handleClose}>
    <header className="popup__header">
      <div className="icon">
        <DangerIcon />
      </div>
      <h2 className="dashboard__title heading">Uwaga!</h2>
    </header>
    <div className="popup__body p-30">
      <p className="text-default">{text}</p>
    </div>
    <div className="popup__footer">
      {show_close_button && (
        <button
          type="button"
          className="btn btn-pink btn-sm"
          onClick={handleClose}>
          {cancel_button_text}
        </button>
      )}
      <button
        type="button"
        className="btn btn-green btn-sm"
        onClick={handleConfirm}>
        {submit_button_text}
      </button>
    </div>
  </Modal>
);

export default WarningModal;
