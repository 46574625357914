import { useQuery } from "react-query";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { Dashboard, MainTable } from "components";

import getPendingTimeRecords from "pages/TimeRecords/const/getPendingTimeRecords";

const PendingTimeRecordsList = ({ history }) => {
  const { isFetching: is_loading, data } = useQuery(
    "pending_time_records",
    getPendingTimeRecords
  );

  return (
    <Dashboard
      header={{ title: "Czas pracy do uzupełnienia" }}
      className={`m-b-10 ${data?.length > 0 ? "dashboard--warning" : ""}`}
    >
      <MainTable
        is_loading={is_loading}
        data={data}
        head={[
          {
            label: "Imię i nazwisko",
            name: "user",
            sortable: false,
          },
          {
            label: "Czas rozpoczęcia",
            name: "time_from",
            sortable: false,
          },
        ]}
        empty_text="Brak czasu pracy do zakończenia"
        renderRow={({
          id,
          user,
          time_from,
        }) => (
          <tr
            key={id}
            style={{ cursor: "pointer" }}
            onMouseDown={(event) => {
              if (event.button === 1) {
                window.open(`/time_records/${id}`, "_blank");
              }
            }}
            onClick={() => history.push(`/time_records/${id}`)}
          >
            <td>
              {user?.first_name} {user?.last_name}
            </td>
            <td>
              {time_from ? moment(time_from).format("DD.MM.YYYY HH:mm") : "-"}
            </td>
          </tr>
        )}
      />
    </Dashboard>
  );
};

export default withRouter(PendingTimeRecordsList);
