import moment from "moment";

const initialState = {
  status: "invalid",
  filters: {
    open: true,
    query: {
      "q[time_from_gteq]": moment().format("YYYY-MM-01"),
      "q[time_from_lt]": moment()
        .add(1, "months")
        .format("YYYY-MM-DD"),
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "TASKS_TOGGLE_FILTERS":
      return {
        ...state,
        filters: {
          ...state.filters,
          open: !state.filters.open,
        },
      };
    case "TASKS_SET_FILTERS_QUERY":
      return {
        ...state,
        filters: { ...state.filters, query: action.query },
      };
    case "TASKS_SEARCHING":
      return {
        ...state,
        status: "searching",
      };
    case "TASKS_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "TASKS_SUCCESS":
      return {
        ...state,
        status: "success",
        ...action,
      };
    case "TASKS_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    default:
      return state;
  }
};
