const initialState = {
  status: "invalid",
  filters: {
    open: true,
    query: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "PROJECTS_TOGGLE_FILTERS":
      return {
        ...state,
        filters: { ...state.filters, open: !state.filters.open },
      };
    case "PROJECTS_SET_FILTERS_QUERY":
      return {
        ...state,
        filters: { ...state.filters, query: action.query },
      };
    case "PROJECTS_SEARCHING":
      return {
        ...state,
        status: "searching",
      };
    case "PROJECTS_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "PROJECTS_SUCCESS":
      return {
        ...state,
        status: "success",
        ...action,
      };
    case "PROJECTS_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    default:
      return state;
  }
};
