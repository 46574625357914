import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { Dashboard, MainTable, Main } from "components";
import { parseQuery } from "utils/api";
import {
  getUsers,
  filterUsers,
  toggleFilters,
} from "actions/users";

import { ReactComponent as UserIcon } from "icons/user.svg";

const UsersList = ({
  users,
  toggleFilters,
  getUsers,
  filterUsers,
  history,
  location: { search },
}) => {
  const query = parseQuery(search);
  const [is_loaded, setLoaded] = useState(false);
  const sort = {
    "sort[column]": query["sort[column]"] || "id",
    "sort[order]": query["sort[order]"] || "asc",
  };

  useEffect(() => {
    getUsers({ ...query, ...sort });
    setLoaded(true);
  }, [search]);

  useEffect(() => {
    if (is_loaded) {
      getUsers({ ...query, page: undefined });
    }
  }, [users.filters.query]);

  if (users.status === "failure") {
    return <p>Error</p>;
  }

  const { data, meta } = users;

  return (
    <Main
      page={{
        name: "Użytkownicy",
        icon: <UserIcon />,
        breadcrumbs: [{ name: "Użytkownicy" }],
      }}>
      <Dashboard>
        <MainTable
          is_loading={
            users.status === "invalid" ||
            users.status === "loading"
          }
          is_searching={users.status === "searching"}
          filters_open={users.filters.open}
          toggleFilters={toggleFilters}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            { label: "E-mail", name: "email" },
            { label: "Imię i nazwisko", name: "last_name" },
            { label: "Dział", name: "department" },
          ]}
          empty_text="Brak użytkowników"
          renderRow={({
            id,
            email,
            first_name,
            last_name,
            department,
          }) => (
            <tr
              key={id}
              style={{ cursor: "pointer" }}
              onMouseDown={(event) => {
                if (event.button === 1) {
                  window.open(`/users/${id}`, "_blank");
                }
              }}
              onClick={() => history.push(`/users/${id}`)}>
              <td>{email}</td>
              <td>
                {first_name} {last_name}
              </td>
              <td>{department?.name || "-"}</td>
            </tr>
          )}
          filtersAction={filterUsers}
          filters={[
            {
              type: "input",
              label: "E-mail",
              name: "email",
              search_type: "matches",
            },
            {
              type: "input",
              label: "Imię i nazwisko",
              name: "first_name_or_last_name",
              search_type: "matches",
            },
          ]}
        />
      </Dashboard>
    </Main>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getUsers: (data) => dispatch(getUsers(data)),
  filterUsers: (data) => dispatch(filterUsers(data)),
  toggleFilters: () => dispatch(toggleFilters()),
});
export default connect(
  ({ users }) => ({ users }),
  mapDispatchToProps
)(UsersList);
