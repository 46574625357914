import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-query";
import moment from "moment";
import { Dashboard, MainTable } from "components";
import { parseQuery } from "utils/api";
import {
  getTimeRecords,
  filterTimeRecords,
  toggleFilters,
} from "actions/time_records";

import { secondsToHms } from "utils/helpers";

import getDepartmentsOptions from "utils/select_options/getDepartmentsOptions";
import getUsers from "pages/TimeRecords/const/getUsers";

const CompletedTimeRecordsList = ({
  current_user,
  time_records,
  toggleFilters,
  getTimeRecords,
  filterTimeRecords,
  history,
  location: { search },
}) => {
  const query = parseQuery(search);

  const { isFetching: is_fetching_users, data: users } = useQuery(
    "users",
    getUsers,
    {
      enabled: current_user?.is_admin,
    }
  );

  const { isFetching: is_fetching_departments, data: departments } = useQuery(
    "departments",
    getDepartmentsOptions,
    {
      enabled: current_user?.is_admin,
    }
  );

  const [is_loaded, setLoaded] = useState(false);
  const sort = {
    "sort[column]": query["sort[column]"] || "time_from",
    "sort[order]": query["sort[order]"] || "desc",
  };

  useEffect(() => {
    getTimeRecords({ ...query, ...sort });
    setLoaded(true);
  }, [search]);

  useEffect(() => {
    if (is_loaded) {
      getTimeRecords({ ...query, page: undefined });
    }
  }, [time_records.filters.query]);

  if (time_records.status === "failure") {
    return <p>Error</p>;
  }

  const { data, meta } = time_records;

  return (
    <Dashboard>
      <MainTable
        is_loading={
          time_records.status === "invalid" || time_records.status === "loading"
        }
        is_searching={time_records.status === "searching"}
        filters_open={time_records.filters.open}
        toggleFilters={toggleFilters}
        data={data}
        meta={meta}
        sort={sort}
        head={[
          {
            label: "Imię i nazwisko",
            name: "user",
            sortable: false,
            style: { width: "20%" },
          },
          {
            label: "Czas rozpoczęcia",
            name: "time_from",
            style: { width: "20%" },
          },
          {
            label: "Czas zakończenia",
            name: "time_to",
            style: { width: "20%" },
          },
          {
            label: "Czas pracy",
            name: "time_total",
            style: { width: "20%" },
          },
          {
            label: "Powód nadgodzin",
            name: "description",
            sortable: false,
            style: { width: "10%" },
          },
          {
            label: "Praca zdalna",
            name: "remote_work",
            sortable: false,
            style: { width: "10%" },
          },
        ]}
        empty_text="Brak rekordów"
        renderRow={({
          id,
          user,
          time_to,
          time_from,
          time_total,
          description,
          remote_work,
        }) => (
          <tr
            key={id}
            style={{ cursor: "pointer" }}
            onMouseDown={(event) => {
              if (event.button === 1) {
                window.open(`/time_records/${id}`, "_blank");
              }
            }}
            onClick={() => history.push(`/time_records/${id}`)}
          >
            <td>
              {user?.first_name} {user?.last_name}
            </td>
            <td>
              {time_from ? moment(time_from).format("DD.MM.YYYY HH:mm") : "-"}
            </td>
            <td>
              {time_to ? moment(time_to).format("DD.MM.YYYY HH:mm") : "-"}
            </td>
            <td>{secondsToHms(time_total)}</td>
            <td>{description || "-"}</td>
            <td>{remote_work ? "Tak" : "Nie"}</td>
          </tr>
        )}
        filtersAction={filterTimeRecords}
        filters={[
          current_user.is_admin && {
            type: "multi-select",
            label: "Użytkownik",
            search_type: "in",
            options: users,
            is_loading: is_fetching_users,
            name: "user_id",
          },
          {
            type: "month",
            search_type: "month",
            label: "Miesiąc",
            name: "month",
            default_value: [moment().startOf("month").toDate()],
          },
          current_user.is_admin && {
            type: "multi-select",
            label: "Dział",
            search_type: "in",
            options: departments,
            is_loading: is_fetching_departments,
            name: "user_department_id",
          },
          {
            type: "text",
            label: "Łączny czas pracy:",
            name: "text_time_total",
            value: meta?.time_total ? secondsToHms(meta.time_total) : 0,
          },
        ].filter(Boolean)}
      />
    </Dashboard>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getTimeRecords: (data) => dispatch(getTimeRecords(data)),
  filterTimeRecords: (data) => dispatch(filterTimeRecords(data)),
  toggleFilters: () => dispatch(toggleFilters()),
});

export default connect(
  ({ current_user, time_records }) => ({
    current_user,
    time_records,
  }),
  mapDispatchToProps
)(withRouter(CompletedTimeRecordsList));
