import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useQuery } from "react-query";
import {
  Dashboard,
  MainTable,
  Main,
  Button,
} from "components";
import { parseQuery } from "utils/api";
import {
  getProjects,
  filterProjects,
  toggleFilters,
} from "actions/projects";
import getClients from "./const/getClients";

import { ReactComponent as ProjectIcon } from "icons/project.svg";

const ProjectsList = ({
  projects,
  toggleFilters,
  getProjects,
  filterProjects,
  history,
  location: { search },
}) => {
  const query = parseQuery(search);
  const [is_loaded, setLoaded] = useState(false);
  const {
    isFetching: is_fetching_clients,
    data: clients,
  } = useQuery("clients", getClients);

  const sort = {
    "sort[column]": query["sort[column]"] || "id",
    "sort[order]": query["sort[order]"] || "asc",
  };
  useEffect(() => {
    getProjects({ ...query, ...sort });
    setLoaded(true);
  }, [search]);
  useEffect(() => {
    if (is_loaded) {
      getProjects({ ...query, page: undefined });
    }
  }, [projects.filters.query]);
  if (projects.status === "failure") {
    return <p>Error</p>;
  }
  const { data, meta } = projects;
  return (
    <Main
      page={{
        name: "Projekty",
        icon: <ProjectIcon />,
        breadcrumbs: [{ name: "Projekty" }],
        buttons: (
          <Button
            type="add"
            text="Dodaj projekt"
            path="/projects/new"
          />
        ),
      }}>
      <Dashboard>
        <MainTable
          is_loading={
            projects.status === "invalid" ||
            projects.status === "loading"
          }
          is_searching={projects.status === "searching"}
          filters_open={projects.filters.open}
          toggleFilters={toggleFilters}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            { label: "ID", name: "id" },
            { label: "Nazwa", name: "name" },
            {
              label: "Klient",
              name: "client_name",
              sortable: false,
            },
          ]}
          empty_text="Brak projektów"
          renderRow={({ id, name, client }) => (
            <tr
              key={id}
              style={{ cursor: "pointer" }}
              onMouseDown={(event) => {
                if (event.button === 1) {
                  window.open(`/projects/${id}`, "_blank");
                }
              }}
              onClick={() =>
                history.push(`/projects/${id}`)
              }>
              <td>{id}</td>
              <td>{name}</td>
              <td>{client?.name || "-"}</td>
            </tr>
          )}
          filtersAction={filterProjects}
          filters={[
            {
              type: "input",
              label: "Nazwa",
              name: "name",
              search_type: "matches",
            },
            {
              type: "multi-select",
              label: "Klient",
              search_type: "in",
              options: clients,
              is_loading: is_fetching_clients,
              name: "client_id",
            },
          ]}
        />
      </Dashboard>
    </Main>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getProjects: (data) => dispatch(getProjects(data)),
  filterProjects: (data) => dispatch(filterProjects(data)),
  toggleFilters: () => dispatch(toggleFilters()),
});
export default connect(
  ({ projects }) => ({ projects }),
  mapDispatchToProps
)(ProjectsList);
