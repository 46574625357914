const initialState = {
  status: "invalid",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "ABSENCE_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "ABSENCE_SUCCESS":
      return {
        ...state,
        status: "success",
        ...action,
      };
    case "ABSENCE_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    case "ABSENCE_REJECTED": {
      return {
        ...state,
        data: action.data,
      };
    }
    default:
      return state;
  }
};
