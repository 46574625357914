import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { connect } from "react-redux";
import {
  Button,
  Input,
  ModalDelete,
} from "expano-components";
import {
  Main,
  Dashboard,
  PageLoader,
  Select,
} from "components";
import {
  getProject,
  deleteProject,
  updateProject,
} from "actions/projects";
import {
  Field,
  reduxForm,
  SubmissionError,
} from "redux-form";
import getClients from "./const/getClients";

import { ReactComponent as ProjectIcon } from "icons/project.svg";

const InputField = (props) => <Input {...props} />;

const ProjectEdit = ({
  project,
  getProject,
  updateProject,
  deleteProject,
  pristine,
  change,
  handleSubmit,
  match: { params },
  history,
}) => {
  const [
    is_open_delete_modal,
    handleOpenDeleteModal,
  ] = useState(false);
  const {
    isFetching: is_fetching_clients,
    data: clients,
  } = useQuery("clients", getClients);

  useEffect(() => {
    getProject(params.id);
  }, []);

  if (
    !project ||
    project.status === "invalid" ||
    project.status === "loading"
  )
    return <PageLoader />;

  if (project.status === "failure") {
    return <p>Error</p>;
  }
  const submit = (values) =>
    updateProject(params.id, values)
      .then(() => history.push(`/projects`))
      .catch((err) => {
        throw new SubmissionError(err);
      });
  return (
    <>
      {is_open_delete_modal && (
        <ModalDelete
          name={project?.data?.name}
          handleClose={() => handleOpenDeleteModal(false)}
          handleDelete={() => {
            deleteProject(params.id).then(() => {
              handleOpenDeleteModal(false);
              history.push("/projects");
            });
          }}
        />
      )}
      <Main
        page={{
          name: project?.data?.name,
          icon: <ProjectIcon />,
          breadcrumbs: [
            { name: "Projekty", path: "/projects" },
            { name: project?.data?.name },
          ],
          buttons: (
            <Button
              type="delete"
              text="Usuń projekt"
              onClick={() => handleOpenDeleteModal(true)}
            />
          ),
        }}>
        <div className="dashboard-wrapper-flex">
          <div className="column-half">
            <Dashboard
              header={{
                title: "Podstawowe dane",
                icon: "info",
              }}>
              <form
                noValidate
                onSubmit={handleSubmit(submit)}>
                <div className="dashboard__form__submit">
                  <Field
                    name="name"
                    label="Nazwa"
                    component={InputField}
                  />
                  <Field
                    name="client_id"
                    type="hidden"
                    component={InputField}
                  />
                  <Select
                    isLoading={is_fetching_clients}
                    options={clients}
                    label="Klient"
                    defaultValue={
                      project?.data?.client
                        ? {
                            label: project.data.client.name,
                            value: project.data.client.id,
                          }
                        : {}
                    }
                    onChange={(value) =>
                      change("client_id", value)
                    }
                  />
                </div>
                <div className="dashboard__form__footer">
                  <Button
                    type="cancel"
                    onClick={() =>
                      history.push("/projects")
                    }
                  />
                  <Button
                    disabled={pristine}
                    type="save"
                    onClick={handleSubmit(submit)}
                  />
                </div>
              </form>
            </Dashboard>
          </div>
        </div>
      </Main>
    </>
  );
};

const mapStateToProps = ({ project }) => ({
  project,
  initialValues: {
    name: project?.data?.name,
  },
});

const mapDispatchToProps = (dispatch) => ({
  getProject: (id) => dispatch(getProject(id)),
  updateProject: (id, data) =>
    dispatch(updateProject(id, data)),
  deleteProject: (id) => dispatch(deleteProject(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "edit-project",
    enableReinitialize: true,
  })(ProjectEdit)
);
