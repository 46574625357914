import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import { reducer as toastr } from "react-redux-toastr";

import current_user from "./current_user";
import users from "./users";
import user from "./user";
import clients from "./clients";
import client from "./client";
import projects from "./projects";
import project from "./project";
import time_records from "./time_records";
import time_record from "./time_record";
import tasks from "./tasks";
import task from "./task";
import absence from "./absence";
import accepted_absences from "./accepted_absences";
import unaccepted_absences from "./unaccepted_absences";
import sidebar from "./sidebar";
import departments from "./departments";
import department from "./department";

export default combineReducers({
  current_user,
  users,
  user,
  clients,
  client,
  projects,
  project,
  time_records,
  time_record,
  tasks,
  task,
  absence,
  accepted_absences,
  unaccepted_absences,
  form,
  toastr,
  sidebar,
  departments,
  department,
});
