import React from "react";
import { Watch } from "react-loader-spinner";

const PageLoader = ({ width = 40, height = 40 }) => {
  return (
    <div className="main-dashboard">
      <div className="page-loader">
        <div className="loader-holder">
          <Watch color="#2959F8" height={width} width={height} />
        </div>
      </div>
    </div>
  );
};

export default PageLoader;
