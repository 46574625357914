import { toastr } from "react-redux-toastr";
import axios from "axios";
import {
  api_url,
  createAxios,
  client,
  apiFormatter,
} from "utils/api";
import {
  removeCookie,
  getCookie,
  setCookie,
} from "utils/cookies";

export const authorizeUser = () => async (dispatch) => {
  dispatch({ type: "CURRENT_USER_LOADING" });
  try {
    const token = getCookie("token");
    if (Boolean(token)) {
      createAxios(token);
      const { data } = await client.get(
        `/users/current?include=company`
      );
      dispatch({
        type: "CURRENT_USER_SUCCESS",
        data: apiFormatter(data),
      });
    } else {
      dispatch({ type: "CURRENT_USER_FAILURE" });
    }
  } catch (error) {
    dispatch({ type: "CURRENT_USER_FAILURE" });
  }
};

export const handleLogin = (data) => {
  axios
    .post(`${api_url}/users/sign_in`, data.user)
    .then(({ data: { auth_token } }) => {
      setCookie("token", auth_token).then(() => {
        window.location.pathname = "/";
      });
    })
    .catch(() => {
      toastr.error(
        "Błąd",
        "Wystąpił błąd podczas logowania"
      );
    });
};

export const handleLogout = () => {
  removeCookie("token").then(
    () => (window.location.pathname = "/")
  );
};

export const handleLoginByAuthToken = async (code) => {
  try {
    const type = code.split("|")[0];
    const token = code.split("|")[1];
    if (type !== "auth_token") {
      throw new Error("Nieprawidłowy kod autoryzacyjny");
    }
    const {
      data: { auth_token },
    } = await axios.post(
      `${api_url}/users/sign_in_by_token`,
      {
        token,
      }
    );
    toastr.success("Sukces", "Zalogowano pomyślnie");

    await setCookie("token", auth_token);
    window.location.pathname = "/";
  } catch (error) {
    toastr.error("Błąd", error?.message || "Wystąpił błąd");
  }
};
