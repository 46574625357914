import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useQuery } from "react-query";
import { Button, Input, Checkbox } from "expano-components";
import {
  Main,
  Dashboard,
  PageLoader,
  Select,
} from "components";
import { getUser, updateUser } from "actions/users";
import {
  Field,
  reduxForm,
  SubmissionError,
} from "redux-form";
import { ReactComponent as UserIcon } from "icons/user.svg";
import getDepartmentsOptions from "utils/select_options/getDepartmentsOptions";

const InputField = (props) => (
  <Input
    {...props}
    input={{ ...props.input, readOnly: true }}
  />
);
const CheckField = (props) => <Checkbox {...props} />;

const UserEdit = ({
  current_user,
  user,
  getUser,
  updateUser,
  pristine,
  handleSubmit,
  match: { params },
  history,
  change,
}) => {
  useEffect(() => {
    getUser(params.id);
  }, []);

  const {
    isFetching: is_fetching_departments,
    data: departments,
  } = useQuery("departments", getDepartmentsOptions);

  if (
    user.status === "invalid" ||
    user.status === "loading"
  )
    return <PageLoader />;

  if (user.status === "failure") {
    return <p>Error</p>;
  }
  const submit = (values) =>
    updateUser(params.id, values)
      .then(() => history.push(`/users`))
      .catch((err) => {
        throw new SubmissionError(err);
      });
  return (
    <Main
      page={{
        name: "Edytuj użytkownika",
        icon: <UserIcon />,
        breadcrumbs: [
          { name: "Użytkownicy", path: "/users" },
          { name: "Edytuj użytkownika" },
        ],
      }}>
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <Dashboard
            header={{
              title: "Podstawowe dane",
              icon: "info",
            }}
            footer={{
              buttons: (
                <>
                  <Button
                    type="cancel"
                    onClick={() => history.push("/users")}
                  />
                  <Button
                    key="submit"
                    disabled={pristine}
                    type="save"
                    onClick={handleSubmit(submit)}
                  />
                </>
              ),
            }}>
            <form
              noValidate
              onSubmit={handleSubmit(submit)}>
              <div className="dashboard__form__submit">
                <Field
                  name="first_name"
                  label="Imię"
                  component={InputField}
                />
                <Field
                  name="last_name"
                  label="Nazwisko"
                  component={InputField}
                />
                <Field
                  name="email"
                  label="E-mail"
                  component={InputField}
                />
                <Field
                  name="is_wrong_hours_alert_enabled"
                  label="Powiadamiaj o błędnych godzinach"
                  component={CheckField}
                />
                  <Field
                  name="has_access_to_financial_reports"
                  label="Dostęp do raportów finansowych"
                  component={CheckField}
                />
                {current_user.is_admin && (
                  <>
                    <Field
                      name="department_id"
                      type="hidden"
                      component={InputField}
                    />
                    <Select
                      options={departments}
                      isLoading={is_fetching_departments}
                      label="Dział"
                      defaultValue={
                        user?.data?.department
                          ? {
                              label:
                                user?.data?.department
                                  ?.name,
                              value:
                                user?.data?.department?.id,
                            }
                          : {}
                      }
                      onChange={(value) =>
                        change("department_id", value)
                      }
                    />
                  </>
                )}
              </div>
            </form>
          </Dashboard>
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = ({ current_user, user }) => ({
  current_user,
  user,
  initialValues: {
    first_name: user?.data?.first_name,
    last_name: user?.data?.last_name,
    email: user?.data?.email,
    is_wrong_hours_alert_enabled:
      user?.data?.is_wrong_hours_alert_enabled,
    department_id: user?.data?.department_id,
    has_access_to_financial_reports: user?.data?.has_access_to_financial_reports
  },
});

const mapDispatchToProps = (dispatch) => ({
  getUser: (id) => dispatch(getUser(id)),
  updateUser: (id, data) => dispatch(updateUser(id, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "edit-user",
    enableReinitialize: true,
  })(UserEdit)
);
