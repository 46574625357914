const initialState = {
  status: "invalid",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "DEPARTMENT_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "DEPARTMENT_SUCCESS":
      return {
        ...state,
        status: "success",
        ...action,
      };
    case "DEPARTMENT_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    case "ADD_USER_TO_DEPARTMENT":
      return {
        ...state,
        data: {
          ...state.data,
          users: [...state.data.users, action.data],
        },
      };
    case "REMOVE_USER_TO_DEPARTMENT": {
      return {
        ...state,
        data: {
          ...state.data,
          users: state.data.users.filter(
            ({ id }) => id !== action.user_id
          ),
        },
      };
    }
    default:
      return state;
  }
};
