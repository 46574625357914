import React from "react";
import PropTypes from "prop-types";

import { ReactComponent as InfoIcon } from "icons/info.svg";
import { ReactComponent as UserIcon } from "icons/user.svg";
import { ReactComponent as ProjectIcon } from "icons/project.svg";

import StyledHeader from "./styles";

const getIcon = (icon) => {
  switch (icon) {
    case "info":
      return <InfoIcon />;
    case "user":
      return <UserIcon />;
    case "project":
      return <ProjectIcon />;
    default:
      return null;
  }
};

const Header = ({ icon, title, aside }) => {
  return (
    <StyledHeader>
      <div className="box">
        <div className="title-wrapper">
          {getIcon(icon)}
          <h2 className="title">{title}</h2>
        </div>
      </div>
      <div className="box box-buttons">{aside}</div>
    </StyledHeader>
  );
};

Header.defaultProps = {
  icon: "",
  title: "",
  aside: null,
};

Header.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  aside: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Header;
