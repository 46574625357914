import React from "react";
import Pagination from "../Pagination";
import {
  Head,
  Loading,
  Filters,
  Empty,
  ActionButtons,
} from "./components";

import StyledMainTable from "./styles";

const MainTable = ({
  is_loading,
  is_searching,
  data,
  filters,
  filtersAction,
  meta,
  head,
  sort,
  empty_text,
  renderRow,
  filters_open,
  toggleFilters,
  buttons,
  products_table = false,
}) => (
  <>
    {filters?.length > 0 && (
      <Filters
        filtersAction={filtersAction}
        filters_open={filters_open}
        toggleFilters={toggleFilters}
        data={filters}
      />
    )}
    {buttons && <ActionButtons>{buttons}</ActionButtons>}
    {products_table ? (
      <ul
        className={`main-table__products-list ${
          is_searching ? "searching" : ""
        }`}
      >
        <li>
          <StyledMainTable searching={is_searching}>
            <Head data={head} sort={sort} />
          </StyledMainTable>
        </li>
        {data?.length > 0 &&
          data.map((item) => renderRow?.(item))}
      </ul>
    ) : (
      <StyledMainTable searching={is_searching}>
        <Head data={head} sort={sort} />
        {data?.length > 0 && (
          <tbody>
            {data.map((item) => renderRow?.(item))}
          </tbody>
        )}
      </StyledMainTable>
    )}

    {is_loading && <Loading />}
    {data?.length < 1 && <Empty empty_text={empty_text} />}
    {meta && <Pagination {...meta} />}
  </>
);

export default MainTable;
