import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, ModalDelete } from "expano-components";
import { Dashboard } from "components";
import {
  addUserToDepartment,
  removeUserFromDepartment,
} from "actions/departments";
import AddUserModal from "./components/AddUserModal";

const DepartmentUsers = ({
  department,
  addUserToDepartment,
  removeUserFromDepartment,
}) => {
  const [
    is_open_add_user_modal,
    handleOpenAddUserModal,
  ] = useState(false);

  const [user_to_remove, setUserToRemove] = useState(null);

  return (
    <div className="column-half">
      {user_to_remove?.id && (
        <ModalDelete
          name={`użytkownika ${
            user_to_remove.first_name
          } ${user_to_remove?.last_name || ""} z działu ${
            department.name
          }?`}
          handleDelete={async () => {
            await removeUserFromDepartment(
              user_to_remove.id
            );
            setUserToRemove(null);
          }}
          handleClose={() => setUserToRemove(null)}
        />
      )}
      {is_open_add_user_modal && (
        <AddUserModal
          department_id={department.id}
          handleConfirm={async (user_id) => {
            await addUserToDepartment({
              department_id: department.id,
              user_id,
            });
            handleOpenAddUserModal(false);
          }}
          handleClose={() => handleOpenAddUserModal(false)}
        />
      )}
      <Dashboard
        header={{
          title: "Użytkownicy",
          icon: "user",
        }}
        footer={{
          buttons: (
            <Button
              type="add"
              text="Dodaj użytkownika"
              onClick={() => handleOpenAddUserModal(true)}
            />
          ),
        }}>
        {department?.users?.length > 0 && (
          <ul className="dashboard__list">
            {department?.users.map(
              ({ email, first_name, last_name, id }) => (
                <li
                  className="dashboard__list-item"
                  key={id}>
                  <p>
                    {first_name} {last_name} ({email})
                  </p>
                  <div style={{ marginLeft: "auto" }}>
                    <Button
                      type="delete-bin"
                      onClick={() =>
                        setUserToRemove({
                          id,
                          first_name,
                          last_name,
                        })
                      }
                    />
                  </div>
                </li>
              )
            )}
          </ul>
        )}
      </Dashboard>
    </div>
  );
};

const mapStateToProps = ({ department }) => ({
  department: department?.data,
});

const mapDispatchToProps = (dispatch) => ({
  addUserToDepartment: (data) =>
    dispatch(addUserToDepartment(data)),
  removeUserFromDepartment: (id) =>
    dispatch(removeUserFromDepartment(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DepartmentUsers);
