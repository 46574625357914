import React from "react";
import { ReactComponent as ClockIcon } from "icons/clock.svg";
import { ReactComponent as TaskIcon } from "icons/task.svg";
import { ReactComponent as HolidayIcon } from "icons/holiday.svg";
import { ReactComponent as ProjectIcon } from "icons/project.svg";
import { ReactComponent as ClientIcon } from "icons/client.svg";
import { ReactComponent as UserIcon } from "icons/user.svg";
import { ReactComponent as DepartmentIcon } from "icons/department.svg";
import { ReactComponent as ChartIcon } from "icons/chart.svg";

const nav = ({
  is_admin = false,
  has_access_to_financial_reports = false,
}) => {
  const routes = [
    {
      name: "Czas pracy",
      type: "single",
      icon: <ClockIcon />,
      items: [
        {
          name: "Czas pracy",
          path: "/time_records",
        },
      ],
    },
    {
      name: "Zadania",
      type: "single",
      icon: <TaskIcon />,
      items: [
        {
          name: "Zadania",
          path: "/tasks",
        },
      ],
    },
    {
      name: "Nieobecności",
      type: "single",
      icon: <HolidayIcon />,
      items: [
        {
          name: "Nieobecności",
          path: "/absences",
        },
      ],
    },
    (is_admin || has_access_to_financial_reports) && {
      name: "Raporty Finansowe",
      type: "single",
      icon: <ChartIcon />,
      items: [
        {
          name: "Raporty finansowe",
          path: "/financial_reports",
        },
      ],
    },
  ];

  const admin_routes = [
    {
      name: "Projekty",
      type: "single",
      icon: <ProjectIcon />,
      items: [
        {
          name: "Projekty",
          path: "/projects",
        },
      ],
    },
    {
      name: "Klienci",
      type: "single",
      icon: <ClientIcon />,
      items: [
        {
          name: "Klienci",
          path: "/clients",
        },
      ],
    },
    {
      name: "Użytkownicy",
      type: "single",
      icon: <UserIcon />,
      items: [
        {
          name: "Użytkownicy",
          path: "/users",
        },
      ],
    },
    {
      name: "Działy",
      type: "single",
      icon: <DepartmentIcon />,
      items: [
        {
          name: "Działy",
          path: "/departments",
        },
      ],
    },
  ];
  return is_admin ? [...routes, ...admin_routes] : routes;
};

export default nav;
