import React from "react";
import { useQuery } from "react-query";
import { Field, reduxForm } from "redux-form";
import { Button, Input } from "expano-components";
import { Modal, MultiSelect, DatePicker } from "components";
import { exportToCsvk } from "actions/time_records";
import getUsers from "pages/TimeRecords/const/getUsers";

const InputField = props => <Input {...props} />;

const GenerateTimeRecordsReportModal = ({ data, pristine, handleSubmit, change, handleClose }) => {
  const submit = values => exportToCsvk(values).then(handleClose);
  const { isFetching: is_fetching_users, data: users } = useQuery("users", getUsers);

  return (
    <Modal size='lg'>
      <header className='popup__header' style={{ padding: "15px 20px" }}>
        <h2 className='dashboard__title heading'>Generuj raport czasu pracy</h2>
        <button className='btn btn-icon' type='button' onClick={handleClose}>
          <svg xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 24 24'>
            <g stroke='none' strokeWidth='1' fill='none'>
              <g
                transform='translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)'
                fill='#A2A5B9'
              >
                <rect x='0' y='7' width='16' height='2' rx='1'></rect>
                <rect
                  transform='translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) '
                  x='0'
                  y='7'
                  width='16'
                  height='2'
                  rx='1'
                />
              </g>
            </g>
          </svg>
        </button>
      </header>
      <div className='popup__body' style={{ padding: 30 }}>
        <form noValidate>
          <div className='form-row grid-2'>
            <Field
              name='date_from'
              type='date'
              label='Data rozpoczęcia'
              change={change}
              component={props => <DatePicker {...props} />}
            />
            <Field
              name='date_to'
              type='date'
              label='Data zakończenia'
              change={change}
              component={props => <DatePicker {...props} />}
            />
          </div>
          <Field name='user_ids' type='hidden' component={InputField} />
          <MultiSelect
            options={users}
            isLoading={is_fetching_users}
            label='Użytkownicy'
            onChange={values =>
              values?.length > 0
                ? change(
                    "user_ids",
                    values.map(({ value }) => value)
                  )
                : change("user_ids", [])
            }
          />
        </form>
      </div>
      <div className='popup__footer'>
        <Button type='cancel' onClick={handleClose} />
        <Button
          disabled={pristine}
          type='save'
          text='Generuj raport'
          onClick={handleSubmit(submit)}
        />
      </div>
    </Modal>
  );
};

export default reduxForm({ form: "generate-report", enableReinitialize: true })(
  GenerateTimeRecordsReportModal
);
