import { TimeRecords } from "pages";

const time_record_routes = [
  {
    path: "/time_records",
    component: TimeRecords.List,
  },
  {
    path: "/time_records/new",
    component: TimeRecords.New,
  },
  {
    path: "/time_records/:id",
    component: TimeRecords.Edit,
  },
];

export default time_record_routes;
