const absence_kind_options = [
  { label: "Urlop", value: "vacation" },
  {
    label: "Urlop na żądanie",
    value: "vacation_on_request",
  },
  { label: "Chorobowe", value: "sick_leave" },
];

export default absence_kind_options;
