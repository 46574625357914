import React from "react";
import { Header, Footer } from "./components";
import StyledDashboard from "./styles";

const Dashboard = ({
  className,
  header,
  footer,
  children,
}) => {
  return (
    <StyledDashboard className={className}>
      {header && <Header {...header} />}
      {children}
      {footer && <Footer {...footer} />}
    </StyledDashboard>
  );
};

export default Dashboard;
