import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  Dashboard,
  MainTable,
  Main,
  Button,
} from "components";
import { parseQuery } from "utils/api";
import {
  getDepartments,
  filterDepartments,
  toggleFilters,
} from "actions/departments";

import { ReactComponent as DepartmentIcon } from "icons/department.svg";

const DepartmentList = ({
  departments,
  toggleFilters,
  getDepartments,
  filterDepartments,
  history,
  location: { search },
}) => {
  const query = parseQuery(search);
  const [is_loaded, setLoaded] = useState(false);
  const sort = {
    "sort[column]": query["sort[column]"] || "id",
    "sort[order]": query["sort[order]"] || "asc",
  };
  useEffect(() => {
    getDepartments({ ...query, ...sort });
    setLoaded(true);
  }, [search]);
  useEffect(() => {
    if (is_loaded) {
      getDepartments({ ...query, page: undefined });
    }
  }, [departments.filters.query]);
  if (departments.status === "failure") {
    return <p>Error</p>;
  }
  const { data, meta } = departments;
  return (
    <Main
      page={{
        name: "Działy",
        icon: <DepartmentIcon />,
        breadcrumbs: [{ name: "Działy" }],
        buttons: (
          <Button
            type="add"
            text="Dodaj dział"
            path="/departments/new"
          />
        ),
      }}>
      <Dashboard>
        <MainTable
          is_loading={
            departments.status === "invalid" ||
            departments.status === "loading"
          }
          is_searching={departments.status === "searching"}
          filters_open={departments.filters.open}
          toggleFilters={toggleFilters}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            { label: "ID", name: "id" },
            { label: "Nazwa", name: "name" },
          ]}
          empty_text="Brak działów"
          renderRow={({ id, name }) => (
            <tr
              key={id}
              style={{ cursor: "pointer" }}
              onMouseDown={(event) => {
                if (event.button === 1) {
                  window.open(
                    `/departments/${id}`,
                    "_blank"
                  );
                }
              }}
              onClick={() =>
                history.push(`/departments/${id}`)
              }>
              <td>{id}</td>
              <td>{name}</td>
            </tr>
          )}
          filtersAction={filterDepartments}
          filters={[
            {
              type: "input",
              label: "Nazwa",
              name: "name",
              search_type: "matches",
            },
          ]}
        />
      </Dashboard>
    </Main>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getDepartments: (data) => dispatch(getDepartments(data)),
  filterDepartments: (data) =>
    dispatch(filterDepartments(data)),
  toggleFilters: () => dispatch(toggleFilters()),
});
export default connect(
  ({ departments }) => ({ departments }),
  mapDispatchToProps
)(DepartmentList);
