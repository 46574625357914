import React, { useEffect } from "react";
import { connect } from "react-redux";
import { authorizeUser } from "actions/account";
import { removeCookie } from "utils/cookies";

import { PageLoader } from "components";
import ProtectedView from "./ProtectedView";
import AppLogin from "./AppLogin";

const App = ({ current_user, authorizeUser }) => {
  useEffect(() => {
    authorizeUser();
  }, []);

  if (
    current_user?.status === "invalid" ||
    current_user.status === "loading"
  ) {
    return <PageLoader />;
  }

  if (current_user?.status === "failure") {
    removeCookie("token");
    return <AppLogin />;
  }
  return <ProtectedView />;
};

export default connect(
  ({ current_user }) => ({ current_user }),
  (dispatch) => ({
    authorizeUser: () => dispatch(authorizeUser()),
  })
)(App);
