import React, { useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router";
import { Dashboard, MainTable } from "components";
import {
  getUnAcceptedAbsences,
  acceptAbsence,
  filterUnAcceptedAbsences,
  toggleUnAcceptedAbsences,
} from "actions/absences";

import absence_kind_options from "utils/select_options/absence_kind_options";
import { Button } from "expano-components";

const AbsencesList = ({
  getUnAcceptedAbsences,
  acceptAbsence,
  toggleUnAcceptedAbsences,
  filterUnAcceptedAbsences,
  history,
  unaccepted_absences,
  current_user,
  is_fetching_departments,
  departments,
}) => {
  useEffect(() => {
    getUnAcceptedAbsences();
  }, []);

  const { status, data, filters } = unaccepted_absences;
  return (
    <Dashboard
      className="m-b-10"
      header={{ title: "Nieobecności do akceptacji" }}>
      <MainTable
        is_loading={
          status === "invalid" || status === "loading"
        }
        data={data}
        filters_open={filters.open}
        toggleFilters={toggleUnAcceptedAbsences}
        head={[
          {
            label: "Imię i nazwisko",
            name: "user",
            sortable: false,
            style: { width: "200px" },
          },
          {
            label: "Data od",
            name: "date_from",
            style: { width: "150px" },
            sortable: false,
          },
          {
            label: "Data do",
            name: "date_to",
            style: { width: "150px" },
            sortable: false,
          },
          {
            label: "Rodzaj",
            name: "kind",
            style: { width: "150px" },
            sortable: false,
          },
          {
            label: "Dział",
            name: "department",
            style: { width: "150px" },
            sortable: false,
          },
          {
            label: "Powód",
            name: "reason",
            sortable: false,
            style: { width: "200px" },
          },
          {
            label: "",
            name: "actions",
            sortable: false,
            style: { width: "230px" },
          },
        ]}
        empty_text="Brak nieobecności do potwierdzenia"
        filtersAction={filterUnAcceptedAbsences}
        renderRow={({
          id,
          user,
          date_from,
          date_to,
          reason,
          kind,
        }) => (
          <tr key={id} style={{ cursor: "default" }}>
            <td>
              {user?.first_name} {user?.last_name}
            </td>
            <td>
              {moment(date_from).format("DD.MM.YYYY")}
            </td>
            <td>{moment(date_to).format("DD.MM.YYYY")}</td>
            <td>
              {absence_kind_options.find(
                ({ value }) => value === kind
              )?.label || "-"}
            </td>
            <td>{user?.department?.name || "-"}</td>
            <td>{reason || "-"}</td>
            <td>
              {current_user?.is_admin && (
                <Button
                  type="button"
                  text="Akceptuj"
                  className="btn btn-sm btn-green m-r-10"
                  onClick={() => acceptAbsence(id)}
                />
              )}
              <Button
                type="button"
                text="Edytuj"
                onClick={() =>
                  history.push(`/absences/${id}`)
                }
              />
            </td>
          </tr>
        )}
        filters={[
          current_user.is_admin && {
            type: "multi-select",
            label: "Dział",
            search_type: "in",
            options: departments,
            is_loading: is_fetching_departments,
            name: "user_department_id",
          },
        ].filter(Boolean)}
      />
    </Dashboard>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getUnAcceptedAbsences: (data) =>
    dispatch(getUnAcceptedAbsences(data)),
  acceptAbsence: (id) => dispatch(acceptAbsence(id)),
  filterUnAcceptedAbsences: (data) =>
    dispatch(filterUnAcceptedAbsences(data)),
  toggleUnAcceptedAbsences: () =>
    dispatch(toggleUnAcceptedAbsences()),
});
export default connect(
  ({ current_user, unaccepted_absences }) => ({
    current_user,
    unaccepted_absences,
  }),
  mapDispatchToProps
)(withRouter(AbsencesList));
